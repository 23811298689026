/* eslint-disable react/jsx-boolean-value */
import React, { useState } from 'react';
import {
  ArrowLeftOutlined,
  HomeFilled,
  UploadOutlined,
} from '@ant-design/icons';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { DatePicker, Form, FormInstance, Radio, Upload } from 'antd';
import { toast } from 'react-toastify';
import locale from 'antd/es/date-picker/locale/pt_BR';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import LayoutAppCMS from '../../../../layouts/app';
import { PageDescription } from '../../../../components/shered/HeaderCards';
import useDocumentTitle from '../../../../hooks/useDocumentTitle';
import { Button } from '../../../../components/shered/Button';
import { TitleProps } from '../../../../global/interfaces/TitleProps';
import { Input } from '../../../../components/shered/Input';
import api from '../../../../services/api';
import { Checkbox } from '../../../../components/shered/Checkbox';
import { Terms } from './terms';
import { Container, Content, ContentButton } from './styles';
import { treatmentError } from '../../../../hooks/erros';

interface IPropsFormAcreditation {
  hga_id: number;
  accreditation_code: string;
  start_date: string;
  last_review: string;
  sampling_accreditation: boolean;
  expiration_date: string;
  terms: boolean;
}

export function CreateAccreditation({ title }: TitleProps) {
  useDocumentTitle(title);
  const history = useHistory();
  const { laboratoryName }: any = useLocation().state;
  const { hga_id }: any = useParams();
  const formRef = React.useRef<FormInstance>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const file_Size = 125829120; /* Just only NUMBER */

  const breadcrumb = [
    {
      label: <HomeFilled />,
      link: '/dashboard',
    },
    {
      label: 'Laboratórios',
      link: '/laboratorio',
    },
    {
      label: `${laboratoryName}`,
    },
    {
      label: 'Nova acreditação',
    },
  ];

  const buttons = [
    <Button
      onClick={() => history.goBack()}
      icon={<ArrowLeftOutlined />}
      size="large"
    >
      Voltar
    </Button>,
  ];

  const onReset = () => {
    formRef.current?.resetFields();
    setFile(null);
    setChecked(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleClick = (e: CheckboxChangeEvent) => {
    setChecked(e.target.checked);
  };

  const createFormData = (values: IPropsFormAcreditation) => {
    const formData = new FormData();
    formData.append('accreditation_code', values.accreditation_code);
    formData.append('start_date', values.start_date);
    formData.append('last_review', values.last_review);
    formData.append(
      'sampling_accreditation',
      values.sampling_accreditation.toString()
    );
    formData.append('terms', values.terms.toString());
    formData.append('hga_id', values.hga_id.toString());
    if (file) {
      formData.append('file', file);
    }
    return formData;
  };

  const onFinish = async (values: any) => {
    try {
      setIsLoading(true);

      const formData = createFormData(values);
      await api.post('/laboratory', formData);
      toast.success('Acreditação cadastrada com sucesso');
    } catch (error) {
      const err = treatmentError(error.data.message);
      toast.error(err);
    }
    setIsLoading(false);
    onReset();
    setFile(null);
  };

  return (
    <LayoutAppCMS>
      <PageDescription
        description="Adicione e monitore os laboratórios alterar para Adicione a acreditação do seu laboratório"
        title={title}
        breadcrumb={breadcrumb}
        buttons={buttons}
      />
      <Container>
        <Content>
          <Form
            ref={formRef}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 10 }}
            style={{ width: '100%' }}
            onFinish={onFinish}
          >
            <Form.Item
              label="Código de acreditação"
              name="accreditation_code"
              rules={[
                {
                  required: true,
                  message: 'Por favor informe o código',
                },
              ]}
              hasFeedback
            >
              <Input placeholder="Informe o Código" required />
            </Form.Item>
            <Form.Item
              label="Data de acreditação"
              name="start_date"
              rules={[
                { required: true, message: 'Informe a data de acreditação!' },
              ]}
            >
              <DatePicker
                format="DD/MM/YYYY"
                locale={locale}
                placeholder="Selecione uma data"
              />
            </Form.Item>
            <Form.Item
              label="Data da Última revisão"
              name="last_review"
              rules={[{ required: true, message: 'Informe a data de início!' }]}
            >
              <DatePicker
                format="DD/MM/YYYY"
                locale={locale}
                placeholder="Selecione uma data"
              />
            </Form.Item>
            <Form.Item
              label="Acreditação para coleta"
              name="sampling_accreditation"
              required
              rules={[
                {
                  required: true,
                  message: 'Por favor preencha o campo',
                },
              ]}
            >
              <Radio.Group optionType="button" buttonStyle="solid">
                <Radio style={{ width: '13.25rem' }} value={true}>
                  {' '}
                  Sim{' '}
                </Radio>
                <Radio style={{ width: '13.25rem' }} value={false}>
                  {' '}
                  Não{' '}
                </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="Upload"
              name="file"
              valuePropName="fileList"
              rules={[
                {
                  required: true,
                  message: 'Por favor adicione um Template',
                },
              ]}
              getValueFromEvent={event => {
                return event?.fileList;
              }}
            >
              <Upload
                showUploadList={false}
                maxCount={1}
                beforeUpload={file => {
                  return new Promise((resolve, reject) => {
                    if (file && file.size > file_Size) {
                      reject(new Error('tamanho excedido'));
                      toast.error('Tamanho de arquivo excedido');
                    } else {
                      setFile(file);
                      resolve('Sucess');
                    }
                  });
                }}
              >
                <Button icon={<UploadOutlined />}>Adicionar parâmetros</Button>
                {file && <div>{file?.name}</div>}
              </Upload>
            </Form.Item>
            <Form.Item
              wrapperCol={{ offset: 8, span: 16 }}
              name="terms"
              valuePropName="checked"
              rules={[
                {
                  required: true,
                  message: 'Preencha o campo',
                },
              ]}
            >
              <Checkbox checked={checked} onChange={handleClick}>
                Eu aceito
                <Button onClick={showModal} type="link">
                  Termos e Políticas de privacidade
                </Button>
                do site.
              </Checkbox>
            </Form.Item>
            <ContentButton>
              <Form.Item name="hga_id" initialValue={hga_id} hidden />
              <Button
                type="primary"
                disabled={!checked}
                loading={isLoading}
                htmlType="submit"
              >
                Cadastrar
              </Button>
              <Button
                style={{ marginLeft: '10px' }}
                htmlType="button"
                danger
                onClick={onReset}
              >
                Limpar
              </Button>
            </ContentButton>
          </Form>
        </Content>
      </Container>
      <Terms
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </LayoutAppCMS>
  );
}
