/* eslint-disable global-require */
/* eslint-disable react/jsx-boolean-value */
import React, { useState } from 'react';
import {
  ArrowLeftOutlined,
  HomeFilled,
  UploadOutlined,
} from '@ant-design/icons';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { DatePicker, Form, FormInstance, Upload } from 'antd';
import { toast } from 'react-toastify';
import locale from 'antd/es/date-picker/locale/pt_BR';
import LayoutAppCMS from '../../../../layouts/app';
import { PageDescription } from '../../../../components/shered/HeaderCards';
import useDocumentTitle from '../../../../hooks/useDocumentTitle';
import { Button } from '../../../../components/shered/Button';
import { TitleProps } from '../../../../global/interfaces/TitleProps';
import api from '../../../../services/api';
import { Container, Content, ContentButton } from './styles';
import { treatmentError } from '../../../../hooks/erros';

interface IPropsFormAcreditation {
  hga_id: number;
  accreditation_code: string;
  start_date: string;
  last_review: string;
  sampling_accreditation: boolean;
  expiration_date: string;
  terms: boolean;
}

export function UpdateAccreditation({ title }: TitleProps) {
  useDocumentTitle(title);
  const history = useHistory();
  const { laboratoryName, code }: any = useLocation().state;
  const { hga_id }: any = useParams();
  const formRef = React.useRef<FormInstance>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [checked, setChecked] = useState(false);
  const file_Size = 125829120; /* Just only NUMBER */

  const breadcrumb = [
    {
      label: <HomeFilled />,
      link: '/dashboard',
    },
    {
      label: 'Laboratórios',
      link: '/laboratorio',
    },
    {
      label: `${laboratoryName}`,
    },
    {
      label: 'Editar acreditação',
    },
  ];

  const buttons = [
    <Button
      onClick={() => history.goBack()}
      icon={<ArrowLeftOutlined />}
      size="large"
    >
      Voltar
    </Button>,
  ];

  const onReset = () => {
    formRef.current?.resetFields();
    setFile(null);
    setChecked(false);
  };

  const createFormData = (values: IPropsFormAcreditation) => {
    const dayjs = require('dayjs');
    const formData = new FormData();
    formData.append('accreditation_code', values.accreditation_code);
    formData.append(
      'last_review',
      dayjs(values.last_review).format('YYYY-MM-DD')
    );
    formData.append('hga_id', values.hga_id.toString());
    if (file) {
      formData.append('file', file);
    }
    return formData;
  };

  const onFinish = async (values: any) => {
    setIsLoading(true);
    try {
      const formData = createFormData(values);
      await api.put(`/laboratory/${hga_id}`, formData);
      toast.success('Atualização realizada com sucesso');
      onReset();
      setFile(null);
    } catch (error) {
      const err = treatmentError(error.data.message);
      toast.error(err);
    }

    setIsLoading(false);
  };

  return (
    <LayoutAppCMS>
      <PageDescription
        description="Edite a acreditação"
        title={title}
        breadcrumb={breadcrumb}
        buttons={buttons}
      />
      <Container>
        <Content>
          <Form
            ref={formRef}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 10 }}
            style={{ width: '100%' }}
            autoComplete="off"
            onFinish={onFinish}
            onFinishFailed={error => {
              console.log({ error });
            }}
          >
            <Form.Item
              label="Data da Última revisão"
              name="last_review"
              rules={[{ required: true, message: 'Informe a data de início!' }]}
            >
              <DatePicker
                format="DD/MM/YYYY"
                locale={locale}
                placeholder="Selecione uma data"
              />
            </Form.Item>
            <Form.Item
              label="Upload"
              name="file"
              valuePropName="fileList"
              rules={[
                {
                  required: true,
                  message: 'Por favor adicione um Template',
                },
              ]}
              getValueFromEvent={event => {
                return event?.fileList;
              }}
            >
              <Upload
                showUploadList={false}
                maxCount={1}
                beforeUpload={file => {
                  return new Promise((resolve, reject) => {
                    if (file && file.size > file_Size) {
                      reject(new Error('tamanho excedido'));
                      toast.error('Tamanho de arquivo excedido');
                    } else {
                      setFile(file);
                      setChecked(true);
                      resolve('Sucess');
                    }
                  });
                }}
              >
                <Button icon={<UploadOutlined />}>Adicionar parâmetros</Button>
                {file && <div>{file?.name}</div>}
              </Upload>
            </Form.Item>
            <Form.Item name="accreditation_code" initialValue={code} hidden />
            <Form.Item name="hga_id" initialValue={hga_id} hidden />
            <ContentButton>
              <Button
                type="primary"
                disabled={!checked}
                loading={isLoading}
                htmlType="submit"
              >
                Cadastrar
              </Button>
              <Button
                style={{ marginLeft: '10px' }}
                htmlType="button"
                danger
                onClick={onReset}
              >
                Limpar
              </Button>
            </ContentButton>
          </Form>
        </Content>
      </Container>
    </LayoutAppCMS>
  );
}
