import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html {
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    font-family: "Open Sans", sans-serif;
  }

  body {
    -webkit-font-smoothing: antialiased;
    background: ${props => props.theme['gray-light']};
    line-height: inherit;
    font-size: 16px;
  }

  html, body, #root {
    height: 100vh;
    width: 100%;
  }

  a {
    color: ${props => props.theme['green-600']};
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
    transition: color .3s;

    &:hover {
      color: ${props => props.theme['green-700']};
    }
  }

  textarea {
    resize: vertical;
  }

  button,
  [role="button"],
  select {
    text-transform: none;
    cursor: pointer;
  }

  :disabled {
    cursor: default;
  }

  input::-moz-placeholder, textarea::-moz-placeholder,
  input:-ms-input-placeholder, textarea:-ms-input-placeholder,
  input::placeholder, textarea::placeholder {
    opacity: 1;
    color: #8F9BB3;
  }

  img, video {
    max-width: 100%;
    height: auto;
  }

  [hidden] {
    display: none;
  }

  .focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .ant-dropdown {
    max-height: 400px;
    overflow: auto;
    box-shadow: 0 0 5px 5px #00000014;
  }
`;
