import React, { useEffect } from 'react';
import { Form, Select, Alert } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from '../../../../../components/shered/Button';
import { ContentButton } from './styles';

const { Option } = Select;

export function FormStep5({ onNext, formData, setFormData, onPrevious }: any) {
  const [form] = Form.useForm();
  const {
    stationPhysicalMedium,
    matriz_monit,
  }: { stationPhysicalMedium: any; matriz_monit: any } = formData;
  const filterPoints = stationPhysicalMedium.filter(
    (item: any) => item.matrix === matriz_monit
  );

  const onFinish = (response: string[]) => {
    setFormData({ ...formData, ...response });
    onNext();
  };

  const onFinishFailed = (error: any) => {
    console.log('Ocorreu um erro inesperado', error);
  };

  const filterOption = (input: string, option: any) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  useEffect(() => {
    form.resetFields();
  }, [matriz_monit]);

  return (
    <Form
      form={form}
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Pontos"
        name="pontos_selected"
        rules={[{ required: true, message: 'Selecione um ponto!' }]}
      >
        <Select
          maxTagCount="responsive"
          mode="multiple"
          showSearch
          style={{ width: '100%' }}
          placeholder="Selecione os pontos"
          optionFilterProp="children"
          filterOption={filterOption}
          onSelect={value => console.log(value)}
        >
          {filterPoints?.map((option: any, index: any) => (
            <Option key={index} value={option.full_name}>
              {option.full_name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Alert
        message="Ao atualizar a lista de pontos, as próximas etapas serão redefinidas."
        type="warning"
        showIcon
        style={{ marginTop: 10 }}
      />
      <ContentButton>
        <Button
          type="primary"
          onClick={onPrevious}
          icon={<ArrowLeftOutlined />}
        >
          Anterior
        </Button>
        <Button type="primary" htmlType="submit">
          Próximo
        </Button>
      </ContentButton>
    </Form>
  );
}
