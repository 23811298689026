import styled from 'styled-components';

export const ContentTable = styled.div`
  margin-top: 1rem;
  width: 100%;
  overflow-x: auto;
`;

export const SpaceButton = styled.div`
  button {
    margin-right: 1rem;

    @media (max-width: 768px) {
      margin-right: 0.5rem;
    }
  }
`;

export const FilterContainer = styled.div`
  padding: 8px;
`;
