import styled from 'styled-components';

export const Container = styled.div`
  width: 100%; 
  height: 70px;
  box-shadow: 0 1px 4px -1px rgb(0 0 0 / 15%);
  display: flex;
  align-items: center;
  background-color: white;
  position: fixed; 
  top: 0;           
  left: 0;          
  z-index: 1000;
`;

export const Content = styled.div`
  padding: 0 15px;
  color: ${props => props.theme['green-600']};
  width: 100%;
  max-width: 1200px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > div:first-child {
    display: flex;
    gap: 1rem;
    align-items: center;
    h1 {
      font-size: 1.5rem;
    }
  }
  h5 {
    @media (max-width: 960px) {
      display: none;
    }
  }
`;

export const UserPanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
