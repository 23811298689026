import React, { useState } from 'react';
import { Collapse, theme } from 'antd';
import { ArrowLeftOutlined, HomeFilled } from '@ant-design/icons';
import { useLocation, useParams } from 'react-router-dom';
import { FormStep1 } from '../formStep1';
import { FormStep2 } from '../formStep2';
import { FormStep3 } from '../formStep3';
import LayoutAppCMS from '../../../../../layouts/app';
import { PageDescription } from '../../../../../components/shered/HeaderCards';
import { ContentTable } from './styles';
import { FormStep4 } from '../formStep4';
import { Button } from '../../../../../components/shered/Button';
import { FormStep5 } from '../formStep5';
import { FormStep6 } from '../formStep6';
import { FormStep7 } from '../formStep7';
import { FinalStep } from '../finalStep';
import { TitleProps } from '../../../../../global/interfaces/TitleProps';
import useDocumentTitle from '../../../../../hooks/useDocumentTitle';

const { Panel } = Collapse;

export const initialForm = {
  station: null,
  um_condicionante: null,
  tipo_licenca: null,
  matriz_monit: null,
  freq_envio_dados: null,
  versao: null,
  data_inicio: null,
  data_fim: null,
  comentario: null,
};

export const MultiStepForm = ({ title }: TitleProps) => {
  useDocumentTitle(title);
  const { name_enterprise, name_process }: any = useLocation().state;
  const { enterprise_id }: any = useParams();
  const [step, setStep] = useState(0);
  const [conditionResponse, SetConditionsResponse] = useState(null);
  const [stationResponse, SetstationResponse] = useState(null);
  const [allData, setAllData] = useState([initialForm]);
  const [showAcordion, setShowAcordion] = useState(1);
  const [formData, setFormData] = useState(initialForm);
  const { token } = theme.useToken();

  const nextStep = () => {
    setStep(prevStep => prevStep + 1);
  };

  const handleNextAcordion = () => {
    setShowAcordion(showAcordion + 1);
  };

  const handlePreviousAcordion = () => {
    localStorage.setItem('existsParamterValue', 'false');
    localStorage.setItem('paramterValue', JSON.stringify([]));
    setShowAcordion(showAcordion - 1);
  };

  const previousStep = () => setStep(prevStep => prevStep - 1);

  const reset = () => {
    setStep(0);
    setFormData(initialForm);
  };

  const breadcrumb = [
    {
      label: <HomeFilled />,
      link: '/dashboard',
    },
    {
      label: 'Empreendimentos',
      link: '/enterprise',
    },
    {
      label: `${name_enterprise}`,
    },
    {
      label: 'Processos',
      link: `/enterprise/${enterprise_id}/${name_enterprise}/processos`,
    },
    {
      label: `${name_process}`,
    },
    {
      label: 'Nova condicionante',
    },
  ];

  const buttons = [
    <Button onClick={previousStep} icon={<ArrowLeftOutlined />} size="large">
      Voltar
    </Button>,
  ];

  const onSaveFormData = (data: any) => {
    setAllData(prevData => {
      const dataCopy = { ...data, key: Date.now() };
      if (prevData.length === 1 && prevData[0] === initialForm) {
        return [dataCopy];
      }
      return [...prevData, dataCopy];
    });
  };

  return (
    <>
      {step === 0 && (
        <FormStep1
          onNext={nextStep}
          formData={formData}
          setFormData={setFormData}
          SetstationResponse={SetstationResponse}
        />
      )}
      {step === 1 && (
        <FormStep2
          onNext={nextStep}
          onBack={previousStep}
          setFormData={setFormData}
          setStep={setStep}
          setShowAcordion={setShowAcordion}
        />
      )}
      {step === 2 && (
        <LayoutAppCMS>
          <PageDescription
            description="Crie uma nova condicionante"
            title="Nova condicionante"
            breadcrumb={breadcrumb}
            buttons={buttons}
          />
          <ContentTable>
            <Collapse
              style={{
                background: token.colorBgContainer,
              }}
              collapsible="header"
              accordion
              activeKey={showAcordion}
              defaultActiveKey={['1']}
            >
              <Panel header="Dados da condicionante" key="1">
                <FormStep3
                  onNext={() => showAcordion === 1 && handleNextAcordion()}
                  formData={formData}
                  setFormData={setFormData}
                  setStep={setStep}
                  setShowAcordion={setShowAcordion}
                />
              </Panel>
              <Panel
                collapsible={showAcordion >= 2 ? 'header' : 'disabled'}
                header="Legislação"
                key="2"
              >
                <FormStep4
                  formData={formData}
                  setFormData={setFormData}
                  onNext={() => showAcordion === 2 && handleNextAcordion()}
                  onPrevious={handlePreviousAcordion}
                />
              </Panel>
              <Panel
                collapsible={showAcordion >= 3 ? 'header' : 'disabled'}
                header="Pontos"
                key="3"
              >
                <FormStep5
                  formData={formData}
                  setFormData={setFormData}
                  onNext={() => showAcordion === 3 && handleNextAcordion()}
                  onPrevious={handlePreviousAcordion}
                />
              </Panel>
              <Panel
                collapsible={showAcordion >= 4 ? 'header' : 'disabled'}
                header="Parâmetros"
                key="4"
              >
                <FormStep6
                  formData={formData}
                  setFormData={setFormData}
                  onNext={() => showAcordion === 4 && handleNextAcordion()}
                  onPrevious={handlePreviousAcordion}
                />
              </Panel>
              <Panel
                collapsible={showAcordion >= 5 ? 'header' : 'disabled'}
                header="Escopo de Monitoramento por ponto"
                key="5"
              >
                <FormStep7
                  onSaveFormData={onSaveFormData}
                  onNext={nextStep}
                  formData={formData}
                  setFormData={setFormData}
                  onPrevious={handlePreviousAcordion}
                  acordionIndex={showAcordion}
                />
              </Panel>
            </Collapse>
          </ContentTable>
        </LayoutAppCMS>
      )}
      {step === 3 && (
        <FormStep2
          allData={allData}
          onBack={reset}
          onNext={nextStep}
          setFormData={setFormData}
          setStep={setStep}
          setShowAcordion={setShowAcordion}
          setAllData={setAllData}
          SetConditionsResponse={SetConditionsResponse}
        />
      )}
      {step === 4 && (
        <FinalStep
          conditionResponse={conditionResponse}
          onBack={previousStep}
          stationResponse={stationResponse}
          setFormData={setFormData}
          setStep={setStep}
          setShowAcordion={setShowAcordion}
          setAllData={setAllData}
          enterpriseName={name_enterprise}
          processName={name_process}
        />
      )}
    </>
  );
};
