import styled from 'styled-components';

export const ContentTable = styled.div`
  margin-top: 16px;
  width: 100%;
  overflow-x: auto;
`;

export const ContentButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  height: 4rem;
  align-items: center;
  button {
    margin-left: 1rem;
  }
`;