import styled from 'styled-components';

export const Container = styled.div`
  width: calc(33.33%);
  border-radius: 6px;
  height: 350px;
  padding: 7.5px;

  @media (max-width: 960px) {
    max-width: 450px;
    width: 100%;
  }

  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CoverContainer = styled.div`
  background-color: ${props => props.theme['green-300']};
  border-radius: 6px;
  padding: 25px;
  img {
    max-width: 75px;
  }
`;

export const CoverImage = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  background: ${props => props.theme.white};
  text-align: center;
  padding: 1rem;
  height: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;

  button {
    margin-top: 1.5rem;
  }
  h2 {
    font-size: 1.25rem;
  }
`;
