import styled from 'styled-components';

export const ContentTable = styled.div`
  margin-top: 1rem;
  width: 100%;
`;

export const ContentUpload = styled.div`
 div.ant-upload{
    width: 100%;
    margin-bottom: 2rem;
}
`