import styled from 'styled-components';

export const Container = styled.div`  
  margin-top: 16px;
  width: 100%;
  display: flex;   
`

export const Content = styled.div` 
  width: 100%;
  background: #FFFF;
  border-radius: 8px;
  padding: 20px;  
`

export const ContentButton = styled.div` 
  text-align: center;
`