import React from 'react';
import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse, Descriptions, Typography, theme } from 'antd';
import { IstationProps } from '../../../types/pointerView';
import { MapLeaflet } from '../Map/leafletMap';

const { Panel } = Collapse;
const { Title } = Typography;

export interface IPointViewerProps {
  data: IstationProps;
  styleProp?: React.CSSProperties;
}

export function PointViewer({ data, styleProp }: IPointViewerProps) {
  const { token } = theme.useToken();

  const roundToZeroDecimals = (value: number) => {
    return value.toFixed(0);
  };

  return (
    <Collapse
      size="large"
      defaultActiveKey={['1']}
      expandIcon={({ isActive }) => (
        <CaretRightOutlined rotate={isActive ? 90 : 0} />
      )}
      style={{
        background: token.colorBgContainer,
      }}
      accordion
    >
      <Panel header={data.full_name} key={data._id} style={styleProp}>
        <Descriptions
          size="middle"
          column={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 2, xxl: 2 }}
          layout="horizontal"
        >
          <Descriptions.Item label={<Title level={5}>Latitude </Title>}>
            {data.y}
          </Descriptions.Item>
          <Descriptions.Item label={<Title level={5}>Posição relativa</Title>}>
            {data.relative_position}
          </Descriptions.Item>
          <Descriptions.Item label={<Title level={5}>Longitude </Title>}>
            {data.x}
          </Descriptions.Item>
          <Descriptions.Item
            label={<Title level={5}>Tipo de tratamento do efluente</Title>}
          >
            {data.effluent_treat_type}
          </Descriptions.Item>
          <Descriptions.Item label={<Title level={5}>X (m) </Title>}>
            {roundToZeroDecimals(data.UTMx)}
          </Descriptions.Item>
          <Descriptions.Item label={<Title level={5}>Meio Físico </Title>}>
            {data.physical_medium}
          </Descriptions.Item>
          <Descriptions.Item label={<Title level={5}>Y (m) </Title>}>
            {roundToZeroDecimals(data.UTMy)}
          </Descriptions.Item>
          <Descriptions.Item label={<Title level={5}>Vazão referência</Title>}>
            {data.reference_flow_rate}
          </Descriptions.Item>
          <Descriptions.Item label={<Title level={5}>Matriz</Title>}>
            {data.matrix}
          </Descriptions.Item>
          <Descriptions.Item label={<Title level={5}>Uso do solo</Title>}>
            {data.soil_use}
          </Descriptions.Item>
          <Descriptions.Item
            label={<Title level={5}>Bacia hidrográfica</Title>}
          >
            {data.watershed}
          </Descriptions.Item>
          <Descriptions.Item label={<Title level={5}>Profundidade</Title>}>
            {data.depth}
          </Descriptions.Item>
          <Descriptions.Item label={<Title level={5}>Tipo de ponto</Title>}>
            {data.stn_type}
          </Descriptions.Item>
          <Descriptions.Item label={<Title level={5}>Cota terreno</Title>}>
            {data.elevation}
          </Descriptions.Item>
          <Descriptions.Item label={<Title level={5}>Classe ASUP</Title>}>
            {data.sup_water_class}
          </Descriptions.Item>
          <Descriptions.Item label={<Title level={5}>Cota boca do poço</Title>}>
            {data.toc}
          </Descriptions.Item>
          <Descriptions.Item
            label={<Title level={5}>Tipo ambiente ASUP </Title>}
          >
            {data.sup_water_environment}
          </Descriptions.Item>
        </Descriptions>
        <MapLeaflet position={{ lat: data.y, lng: data.x }} />
      </Panel>
    </Collapse>
  );
}
