import React from 'react';
import { Route } from 'react-router-dom';
import { LaboratoryList } from '../views/app/laboratory/listLaboratory';
import { CreateAccreditation } from '../views/app/laboratory/createAccreditation';
import { UpdateAccreditation } from '../views/app/laboratory/upadateAccreditation';
import { CharacterizationList } from '../views/app/laboratory/accreditation';

const routes = [
  {
    path: '/laboratorio',
    component: <LaboratoryList title="Meus Laboratórios" />,
    key: 'laboratory',
  },
  {
    path: '/laboratorio/:laboratory_id/:hga_id/:laboratory_name/acreditacao',
    component: <CharacterizationList title="Acreditação" />,
    key: 'caracterizacao',
  },
  {
    path: '/laboratorio/:laboratory_id/:hga_id/acreditacao/cadastro',
    component: <CreateAccreditation title="Acreditação de laboratório" />,
    key: 'create',
  },
  {
    path: '/laboratorio/:laboratory_id/:hga_id/acreditacao/edicao',
    component: <UpdateAccreditation title="Editar dados da acreditação" />,
    key: 'create',
  },
];

const LaboratoryRouter: React.FC = () => (
  <>
    {routes.map((route, key) => (
      <Route path={route.path} key={key} exact>
        {route.component}
      </Route>
    ))}
  </>
);

export default LaboratoryRouter;
