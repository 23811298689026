import React from 'react';

import { Container } from './styled';

interface IProps {
  urlGov: string;
}

const ButtonSignInGov = ({ urlGov }: IProps) => {
  return (
    <Container href={urlGov}>
      Entrar com&nbsp;
      <img
        src="https://www.gov.br/++theme++padrao_govbr/img/govbr-colorido-b.png"
        alt="gov.br"
      />
    </Container>
  );
};

export default ButtonSignInGov;
