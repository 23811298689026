import styled from 'styled-components';

export const ContentButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  height: 4rem;
  align-items: center;
  button {
    margin-left: 1rem;
  }
`;

export const ContentInputGroup = styled.div`
  display: flex;
  flex-wrap: wrap;

  .ant-form-item{
    padding: 2.5px;
    max-width: 25%;
    width: 100%;
    .ant-input-number, .ant-picker{
      width:100%;
    }    
  }

  @media (max-width: 960px) {
   .ant-form-item{
    max-width:100%;
   }
  }
`