/* eslint-disable no-alert */
import React, { useEffect, useState } from 'react';
import locale from 'antd/es/date-picker/locale/pt_BR';
import { DatePicker, Form, InputNumber, Select } from 'antd';
import moment from 'moment';
import { Button } from '../../../../../components/shered/Button';
import { Input } from '../../../../../components/shered/Input';
import { ContentButton, ContentInputGroup } from './styles';
import api from '../../../../../services/api';

interface IPropsstationPhysicalMedium {
  full_name: string;
  matrix: string;
}

interface IDropList {
  value: string;
  label: string;
}

interface IResponse {
  um_condicionante: number;
  tipo_licenca: string;
  matriz_monit: string;
  freq_envio_dados: Date;
  versao: String;
  data_inicio: Date;
  data_fim: Date;
  comentario: string;
}

export function FormStep3({
  onNext,
  formData,
  setFormData,
  setStep,
  setShowAcordion,
}: any) {
  const {
    stationPhysicalMedium,
    cod_legislacao,
  }: {
    stationPhysicalMedium: IPropsstationPhysicalMedium[];
    cod_legislacao: string[];
  } = formData;
  const [loading, setLoading] = useState(true);
  const [licenseType, setLicenseType] = useState<IDropList[]>([]);
  const [matrixType, setMatrixType] = useState<IDropList[]>([]);
  const [select, setSelect] = useState<boolean>(true);

  const onFinish = (response: IResponse) => {
    onNext();
    setFormData({
      ...formData,
      um_condicionante: response.um_condicionante,
      tipo_licenca: response.tipo_licenca,
      matriz_monit: response.matriz_monit,
      freq_envio_dados: response.freq_envio_dados,
      versao: response.versao,
      data_inicio: response.data_inicio,
      data_fim: response.data_fim,
      comentario: response.comentario,
    });
  };

  const onFinishFailed = (error: any) => {
    console.log('Ocorreu um erro inesperado:', error);
  };

  useEffect(() => {
    const asyncApi = async () => {
      try {
        const respLicense = await api.get(
          '/hga/dropdownList/conditions/license_type'
        );
        setLicenseType(
          respLicense.data.map((item: string) => ({ value: item, label: item }))
        );

        const respMatrix = await api.get(
          '/hga/dropdownList/conditions/monitoring_matrix'
        );
        setMatrixType(
          respMatrix.data.map((item: string) => ({ value: item, label: item }))
        );
      } catch (error) {
        console.error('Ocorreu um erro ao buscar os dados:', error);
      } finally {
        setLoading(false);
      }
    };
    asyncApi();
  }, []);

  const handleSelect = (value: string) => {
    const stationfilter = stationPhysicalMedium?.some(
      (item: IPropsstationPhysicalMedium) => item.matrix === value
    );
    setSelect(stationfilter);
  };

  const isInteger = (value: string | number): boolean => {
    return /^\d+$/.test(value.toString());
  };

  const handleOnCleanCondition = () => {
    onNext();
    setFormData((prevState: any) => ({
      stationPhysicalMedium: prevState.stationPhysicalMedium,
      um_condicionante: prevState.um_condicionante,
      tipo_licenca: prevState.tipo_licenca,
      freq_envio_dados: prevState.freq_envio_dados,
      versao: prevState.versao,
      data_inicio: prevState.data_inicio,
      data_fim: prevState.data_fim,
      comentario: prevState.comentario,
      pontos_selected: [],
    }));
    setStep(2);
    setShowAcordion(1);
  };

  const handleClickNext = () => {
    if (cod_legislacao?.length > 0) {
      window.alert(
        'Com a alteração de um campo inicial todos os campos serão limpos, para manter a consistência dos dados '
      );
      handleOnCleanCondition();
    }
  };

  return (
    <Form
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      layout="vertical"
    >
      <ContentInputGroup>
        <Form.Item
          label="Número da Condicionante"
          name="um_condicionante"
          rules={[
            { required: true, message: 'Informe o número da condicionante!' },
            {
              validator: (_, value) => {
                if (value && !isInteger(value)) {
                  return Promise.reject(
                    new Error('Somente números inteiros são permitidos.')
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <InputNumber min={1} defaultValue={formData[0]?.um_condicionante} />
        </Form.Item>
        <Form.Item
          label="Tipo da licença"
          name="tipo_licenca"
          rules={[{ required: true, message: 'Informe o tipo!' }]}
        >
          <Select
            loading={loading}
            defaultValue={formData[0]?.tipo_licenca ?? 'Selecione o tipo'}
            options={licenseType}
          />
        </Form.Item>
        <Form.Item
          label="Matriz Monitoramento"
          name="matriz_monit"
          rules={[{ required: true, message: 'Informe a Matriz!' }]}
          validateStatus={!select ? 'error' : ''}
          help={!select ? 'Você não possui ponto com essa matriz' : undefined}
        >
          <Select
            onSelect={handleSelect}
            loading={loading}
            defaultValue={formData[0]?.matriz_monit ?? 'Selecione o Matriz'}
            options={matrixType}
          />
        </Form.Item>
        <Form.Item
          label="Frequência de envio dos dados / Dias"
          name="freq_envio_dados"
          rules={[
            { required: true, message: 'Informe a frequência!' },
            {
              validator: (_, value) => {
                if (value && !isInteger(value)) {
                  return Promise.reject(
                    new Error('Somente números inteiros são permitidos.')
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <InputNumber min={1} defaultValue={formData[0]?.freq_envio_dados} />
        </Form.Item>
        <Form.Item
          label="Versão"
          name="versao"
          rules={[
            { required: true, message: 'Informe a versão!' },
            {
              validator: (_, value) => {
                if (value && !isInteger(value)) {
                  return Promise.reject(
                    new Error('Somente números inteiros são permitidos.')
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <InputNumber min={1} defaultValue={formData[0]?.versao} />
        </Form.Item>
        <Form.Item
          label="Data de início"
          name="data_inicio"
          rules={[{ required: true, message: 'Informe a data de início!' }]}
        >
          <DatePicker
            defaultValue={
              formData[0]?.data_inicio &&
              moment(
                new Date(
                  formData[0]?.data_inicio.$y,
                  formData[0]?.data_inicio.$M,
                  formData[0]?.data_inicio.$D,
                  formData[0]?.data_inicio.$H,
                  formData[0]?.data_inicio.$m,
                  formData[0]?.data_inicio.$s
                )
              )
            }
            format="DD/MM/YYYY"
            locale={locale}
            placeholder="Selecione uma data"
          />
        </Form.Item>
        <Form.Item label="Data de Termino" name="data_fim">
          <DatePicker
            defaultValue={
              formData[0]?.data_fim &&
              moment(
                new Date(
                  formData[0]?.data_inicio.$y,
                  formData[0]?.data_inicio.$M,
                  formData[0]?.data_inicio.$D,
                  formData[0]?.data_inicio.$H,
                  formData[0]?.data_inicio.$m,
                  formData[0]?.data_inicio.$s
                )
              )
            }
            format="DD/MM/YYYY"
            locale={locale}
            placeholder="Selecione uma data"
          />
        </Form.Item>
        <Form.Item label="Comentário" name="comentario">
          <Input
            defaultValue={formData[0]?.comentario}
            placeholder="Digite o comentário"
          />
        </Form.Item>
      </ContentInputGroup>
      <ContentButton>
        <Button
          type="primary"
          disabled={!select}
          onClick={handleClickNext}
          htmlType="submit"
        >
          Próximo
        </Button>
      </ContentButton>
    </Form>
  );
}
