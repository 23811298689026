import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 1rem;
  width: 100%;
  margin-bottom:30px;
`

export const ContentButton = styled.div` 
  margin-bottom: 1rem;
  display:flex;
  justify-content: end;
`

export const ContentUpload = styled.div`
 div.ant-upload{
    width: 100%;
    margin-bottom: 2rem;
}
`

export const ContentCard = styled.div`
  width:100%;
  margin-bottom:1rem;
`
export const ContentTable = styled.div`
  margin-top: 1rem;
  width: 100%;
`;