import React, { ReactNode } from 'react';
import { Typography } from 'antd';
import { BreadcrumbBox } from '../../shered/BreadcrumbBox';
import { Content } from './styles';

const { Title, Paragraph } = Typography;

interface BreadcrumbItem {
  label: string | ReactNode;
  link?: string;
}

interface PageSummaryProps {
  pageTitle: string;
  pageDescription?: string;
  itemBreadcrumb: BreadcrumbItem[];
}

export function PageSummary({
  pageTitle,
  pageDescription,
  itemBreadcrumb,
}: PageSummaryProps) {
  return (
    <Content>
      <BreadcrumbBox data={itemBreadcrumb} />
      <Title level={2}>{pageTitle}</Title>
      <Paragraph>
        <strong>{pageDescription}</strong>
      </Paragraph>
    </Content>
  );
}
