import { decode } from 'jsonwebtoken';
import api from '../services/api';

// const RoleRegister = [
//   {
//     type: 'id-auth-portalhga-admin',
//     description: 'Administrador',
//   },
//   {
//     type: 'id-auth-portalhga-analista',
//     description: 'Analista',
//   },
//   {
//     type: 'id-auth-portalhga-apoio',
//     description: 'Apoio',
//   },
//   {
//     type: 'id-auth-portalhga-gestor',
//     description: 'Gestor',
//   },
//   {
//     type: 'laboratorio',
//     description: 'Laboratório',
//   },
//   {
//     type: 'empreendedor',
//     description: 'Empreendedor',
//   },
//   {
//     type: 'socio-proprietario',
//     description: 'Sócio proprietário',
//   },
//   {
//     type: 'consultor-ambiental',
//     description: 'Consultor ambiental',
//   },
//   {
//     type: 'representante-legal',
//     description: 'Representante Legal',
//   },
//   {
//     type: 'operator',
//     description: 'Operador',
//   },
// ];

const RoleConfig = [
  {
    component: 'Buscar empreendimento',
    roles: [
      'id-auth-portalhga-admin',
      'id-auth-portalhga-analista',
      'id-auth-portalhga-apoio',
      'id-auth-portalhga-gestor',
    ],
  },
  {
    component: 'Templates',
    roles: ['id-auth-portalhga-admin'],
  },
  {
    component: 'Importar pontos e condicionantes',
    roles: [
      'id-auth-portalhga-admin',
      'id-auth-portalhga-apoio',
      'id-auth-portalhga-gestor',
    ],
  },
  {
    component: 'Criar Pontos e Condicionantes',
    roles: [
      'id-auth-portalhga-admin',
      'id-auth-portalhga-analista',
      'id-auth-portalhga-gestor',
    ],
  },
  {
    component: 'Caracterização de ponto',
    roles: [
      'id-auth-portalhga-admin',
      'id-auth-portalhga-analista',
      'id-auth-portalhga-gestor',
      'empreendedor',
      'socio-proprietario',
      'consultor-ambiental',
      'representante-legal',
      'operator',
    ],
  },
  {
    component: 'Cadastrar monitoramento',
    roles: [
      'id-auth-portalhga-admin',
      'id-auth-portalhga-analista',
      'id-auth-portalhga-gestor',
      'empreendedor',
      'socio-proprietario',
      'consultor-ambiental',
      'representante-legal',
      'operator',
    ],
  },
  {
    component: 'Editar acreditação',
    roles: [
      'id-auth-portalhga-admin',
      'socio-proprietario',
      'representante-legal',
      'consultor-ambiental',
    ],
  },
  {
    component: 'Nova acreditação',
    roles: [
      'id-auth-portalhga-admin',
      'socio-proprietario',
      'representante-legal',
      'consultor-ambiental',
    ],
  },
];

const getRoleIdByToken = async (): Promise<string> => {
  const token = localStorage.getItem('token');
  if (!token) return '';

  const jwt: any = decode(token);
  if (jwt.role_id === '') {
    return 'no-role';
  }

  const roleType = await api.get(`/role/${jwt.role_id}`);
  return roleType.data.type;
};

const validAccess = async (component: string) => {
  const userRole = await getRoleIdByToken();
  const exists = RoleConfig.findIndex(
    item => item.component === component && item.roles.includes(userRole)
  );
  if (exists >= 0) {
    return true;
  }
  return false;
};

export { validAccess };
