import React, { useEffect, useState } from "react";
import { Form, Select } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button } from "../../../../../components/shered/Button";
import { ContentButton } from "./styles";
import api from "../../../../../services/api";

type DefaultOptionType = string;

const { Option } = Select;

export function FormStep4({ onNext, formData, setFormData, onPrevious }: any) {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const [refreshKey, setRefreshKey] = useState(0);
    const { matriz_monit }: { matriz_monit: string } = formData;
    const [legislation, setLegislation] = useState<DefaultOptionType[]>([]);

    const onFinish = (response: any) => {
        onNext()
        setFormData({ ...formData, ...response })
    };
    const onFinishFailed = (error: any) => {
        console.log("Ocorreu um erro inesperado: ", error);
    };

    const filterOption = (input: string, option: any) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

    useEffect(() => {
        const asyncApi = async () => {
            try {
                const respLegislation = await api.post('/hga/legislationByMatrix', { matrix: matriz_monit });
                const legislationSelect: string[] = [];
                respLegislation.data.map((item: any) => { legislationSelect.push(item.legis_code) })
                setLegislation(legislationSelect)
            } catch (error) {
                console.error('Ocorreu um erro ao buscar os dados: ', error);
            } finally {
                setLoading(false)
            }
        }

        asyncApi()

    }, [matriz_monit, refreshKey])

    useEffect(() => {
        form.resetFields();
    }, [matriz_monit]);

    const refreshApiCall = () => {
        setRefreshKey(oldKey => oldKey + 1);
    }


    return (
        <Form
            form={form}
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                label="Legislação"
                name="cod_legislacao"
                rules={[{ required: true, message: "Por favor selecione uma legislação!" }]}
            >
                <Select
                    defaultValue={formData[0]?.legislacao}
                    onClick={refreshApiCall}
                    loading={loading}
                    maxTagCount="responsive"
                    mode="multiple"
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Escolha a Legislação"
                    optionFilterProp="children"
                    filterOption={filterOption}
                >
                    {legislation.map((option, index) => (
                        <Option key={index} value={option}>
                            {option}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
            <ContentButton>
                <Button type="primary" onClick={onPrevious} icon={<ArrowLeftOutlined />}>
                    Anterior
                </Button>
                <Button type="primary" htmlType="submit" >
                    Próximo
                </Button>
            </ContentButton>
        </Form>
    );
}