import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 1rem;
  width: 100%;
  margin-bottom: 30px;
`;

export const Divider = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 6rem;
  background: ${props => props.theme.white};
  padding-left: 3rem;
  margin-bottom: 1rem;
  border-radius: 6px;

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
