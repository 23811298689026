import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  justify-content: space-around;
  max-width: 1120px;
  width:100%;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 100px 15px 30px; 
`;
