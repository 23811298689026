/* eslint-disable react/destructuring-assignment */
import React, { useCallback, useContext } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { Upload, UploadProps } from 'antd';
import { StationContext } from '../../../providers/Upload-context';
import { ErrorObject } from '../../../types/errorObject';

const { Dragger } = Upload;

type FileUploaderProps = {
  onFileUploadSuccess: (info: any) => void;
  onFileUploadError: (fileName: string) => void;
  onFileRemove: (fileName: string) => void;
  uploadEndpoint: string;
  uploadMessage: string;
  uploadHint: string;
  isConvertUTMtoWGS?: boolean;
};

const defaultProps = {
  isConvertUTMtoWGS: false, // Providing a default value
};

export function FileUploader(props: FileUploaderProps) {
  const token = localStorage.getItem('token') ?? '';
  const { setStation, setError, setLoading } = useContext(StationContext);

  const handleFileUpload = useCallback(
    (info: any) => {
      const { status } = info.file;

      if (status === 'done') {
        props.onFileUploadSuccess(info);
        setStation(info.file.response);
      }
      if (status === 'uploading') {
        setLoading(true);
      }
      if (status === 'error') {
        const errors: ErrorObject[] = [];
        props.onFileUploadError(info.file.response.message);
        if (Array.isArray(info.file.response.message)) {
          info.file.response.message.map((item: any) => errors.push(item));
        } else {
          errors.push(info.file.response.message);
        }

        setError(errors);
      }
    },
    [props.onFileUploadSuccess, setStation, setError, props.onFileUploadError]
  );

  const onFileUploadRemove = (file: any) => {
    props.onFileRemove(file.name);
    setStation(null);
    setError(null);
  };

  const onBeforeUpload = () => {
    setStation(null);
    setError(null);
  };

  const data = props.isConvertUTMtoWGS
    ? { isConvertUTMtoWGS: props.isConvertUTMtoWGS }
    : {};

  const uploadProps: UploadProps = {
    name: 'file',
    data,
    multiple: false,
    action: props.uploadEndpoint,
    onRemove: onFileUploadRemove,
    onChange: handleFileUpload,
    headers: { Authorization: `Bearer ${token}` },
    maxCount: 1,
    beforeUpload: onBeforeUpload,
  };
  return (
    <Dragger height={150} {...uploadProps}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">{props.uploadMessage}</p>
      <p className="ant-upload-hint">{props.uploadHint}</p>
    </Dragger>
  );
}

FileUploader.defaultProps = defaultProps;
