import styled from 'styled-components';

export const Content = styled.div`
  max-width: 25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 4rem;
`;

export const ContentTable = styled.div`
  margin-top: 1rem;
  width: 100%;
`;

export const Form = styled.div``;
