import React from 'react';
import { Modal } from '../../../../components/shered/Modal';

export function Terms({ isModalOpen, handleOk, handleCancel }: any) {
  return (
    <Modal
      footer={[]}
      title="Termos e privacidade"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <p>
        Declaro estar ciente da minha obrigação enquanto Requerente, no
        procedimento de cadastro de Laboratório, de apresentar ao IEMA o
        Requerimento de Cadastro e toda a documentação obrigatória atualizada,
        preenchida, revisada e contendo as assinaturas exigidas, sem nenhuma
        alteração em seu texto original. A ausência de qualquer dado, informação
        desatualizada, omissa, imprecisa e/ou que impeçam a avaliação conclusiva
        do IEMA, ensejará o indeferimento do requerimento de cadastro. Registro,
        ainda, estar ciente de que os dados pessoais preenchidos, de pessoa
        natural ou jurídica de direito público ou privado, serão armazenados por
        tempo indeterminado e utilizado exclusivamente para fins de controle e
        análise interna, sendo os resultados tratados anonimamente de forma
        quantitativa, caso sejam divulgados e/ou publicados. O compartilhamento
        de dados sensíveis somente poderá ocorrer com a Agência Nacional de
        Proteção de Dados (ANPD) e órgãos governamentais de controle, em caso de
        requisição legal. Por fim, sob as penas da lei e de responsabilização
        administrativa, civil e penal, reitero que todas as informações
        prestadas ao IEMA são verdadeiras e contemplam integralmente as
        exigências estabelecidas em Lei n°11.952/2023, a qual acatam na sua
        totalidade. Dou fé e assino documento com ciência do teor integral.
      </p>
    </Modal>
  );
}
