import styled from 'styled-components';

export const ContentButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 64px;
  align-items: center; 
`;

export const ContentTable = styled.div`
  overflow-x: auto;
`