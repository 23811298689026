import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1200px;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const CardSkeleton = styled.div`
  width: calc(33.33%);
  border-radius: 6px;
  height: 350px;
  padding: 7.5px;

  @media (max-width: 960px) {
    width: calc(100%);
  }

  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
