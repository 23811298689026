import styled from 'styled-components';

export const Divider = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 8rem;
  background: ${props => props.theme.white};
  padding-left: 3rem;
  margin-bottom: 1rem;
  border-radius: 6px;

  input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;

export const ContentTable = styled.div`
  margin-top: 1rem;
  width: 100%;
`;

export const ContentButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  height: 4rem;
  align-items: center;
  button {
    margin-left: 1rem;
  }
`;
