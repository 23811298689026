import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: ${props => props.theme.white};
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  width: 60%;
  flex-direction: column;
  justify-content: center;

  padding: 15px;
  img {
    width: 12rem;
  }
  @media screen and (max-width: 991px) {
    width: 100%;
  }
  @media screen and (max-height: 500px) {
    align-items: flex-start;
  }
`;

export const BoxContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 1rem;
  text-align: center;
  flex-direction: column;
  max-width: 500px;
  width: 100%;

  h1 {
    font-size: 1.35rem;
    font-weight: 600;
  }
  p {
    margin-top: 0.5rem;
    max-width: 475px;
    margin-bottom: 2rem;
    @media (max-width: 960px) {
      display: none;
      margin-bottom: 1rem;
    }
  }
  &:first-child {
    border-right: solid ${props => props.theme['Dark Slate Gray']} 1px;
    @media (max-width: 960px) {
      border-right: none;
      border-bottom: solid ${props => props.theme['Dark Slate Gray']} 1px;
    }
  }
`;

export const Footer = styled.footer`
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  height: 2rem;
  a {
    line-height: 1.8;
    text-decoration: none;
    &:first-child {
      margin-right: 10px;
    }
    &:last-child {
      margin-left: 10px;
    }
  }
`;

export const ContentCard = styled.div`
  display: flex;
  max-width: 1200px;
  border-radius: 6px;
  background: ${props => props.theme['gray-light']};
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const FooterContainer = styled.footer`
  font-size: 14px;
  color: #333;
  text-align: center;
  padding: 10px 0;
  position: absolute;
  bottom: 0;  
  width: 100%;
  a{   
    text-decoration: underline;    
  } 
`;


