/* eslint-disable no-param-reassign */
import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

const api = axios.create({
  baseURL: process.env.REACT_APP_URL_API,
});

api.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  response => response,
  error => {
    const { status, data } = error.response || {};

    if (status === 401 && data?.message === 'API CUCA - Unauthorized') {
      localStorage.removeItem('token');
      localStorage.setItem('session_expired', 'true');
      window.location.replace('/login');
    }
    throw error.response;
  }
);

export default api;
