import React, { ReactNode } from 'react';
import { Typography } from 'antd';
import { BreadcrumbBox } from '../BreadcrumbBox';
import { ContentButton, Header } from './styled';

interface BreadcrumbItem {
  label: string | ReactNode;
  link?: string;
}

export interface AppProps {
  title: string;
  description?: string;
  breadcrumb: BreadcrumbItem[];
  buttons?: ReactNode[];
}

const { Title } = Typography;

export function PageDescription({
  title,
  breadcrumb,
  description,
  buttons,
}: AppProps) {
  return (
    <>
      <BreadcrumbBox data={breadcrumb} />
      <Header>
        <Title level={2}>{title}</Title>
        <p>{description}</p>
      </Header>
      <ContentButton>{buttons}</ContentButton>
    </>
  );
}
