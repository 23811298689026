import { ConfigProvider } from 'antd';
import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import App from './App';
import GlobalStyle from './global/styled';
import { defaultTheme } from './global/themes/default';
import { NotificationProvider } from './providers/notificationProvider';

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#2B393B',
        },
      }}
    >
      <ThemeProvider theme={defaultTheme}>
        <NotificationProvider>
          <App />
        </NotificationProvider>
        <GlobalStyle />
      </ThemeProvider>
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
