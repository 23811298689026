import React, { useState } from 'react';
import { NotificationContext } from './NoticationContext-context';

export function NotificationProvider({ children }: any) {
  const [status, setStatus] = useState<any>(false);

  return (
    <NotificationContext.Provider value={{ status, setStatus }}>
      {children}
    </NotificationContext.Provider>
  );
}
