import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { MapProps } from './types';

const containerStyle = {
    width: '100%',
    height: '400px',
};

export const MapLeaflet = ({ position }: MapProps) => {
    return (
        <div id="map" style={containerStyle}>
            <MapContainer style={containerStyle} center={[position.lat, position.lng]} zoom={13} scrollWheelZoom={false}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={[position.lat, position.lng]}>
                    <Popup>
                        Ponto de monitoramento.
                    </Popup>
                </Marker>
            </MapContainer>
        </div>
    )
}