export function convertDate(isoDate: string | null | undefined) {
  if (!isoDate) {
    return '';
  }

  const date = new Date(isoDate);

  const day = `0${date.getUTCDate()}`.slice(-2);
  const month = `0${date.getUTCMonth() + 1}`.slice(-2);
  const year = date.getUTCFullYear().toString();

  return `${day}/${month}/${year}`;
}

export function formatIsoDateToBrDate(isoDate: any) {
  const dateComponents = isoDate.split(/[-T:Z.]/);
  const originalDate = new Date(
    Date.UTC(dateComponents[0], dateComponents[1] - 1, dateComponents[2])
  );

  const formattedDate = originalDate.toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  return formattedDate;
}

export function formatDateToDateAndHour(isoDate: string): string {
  const date = new Date(isoDate);

  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const year = date.getUTCFullYear();

  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');

  return `${day}/${month}/${year} ${hours}:${minutes}`;
}
