import React from 'react';
import Table, { ColumnsType } from 'antd/es/table';
import { Space, Spin, Typography } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import axios from 'axios';
import { ContentTable } from '../../process/cycles/styles';
import { convertDate } from '../../../../utils/dateConvert';

const { Title } = Typography;

interface IPropsParameters {
    id: string;
    name: string;
    url: string;
    folder: string;
    type: string;
    protocol: string;
    date: string;
}

interface IListAccreditationProps {
    accreditation: any;
    isLoading: boolean;
}

export const ListFile: React.FC<IListAccreditationProps> = ({ accreditation, isLoading }) => {

    function downloadFile(file: string) {
        const token = localStorage.getItem('token');
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_URL_API}/file/download/others/${file}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            responseType: 'blob',
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', file);
            document.body.appendChild(link);
            link.click();
        });
    }

    const columns: ColumnsType<IPropsParameters> = [
        {
            title: 'Nome',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Protocolo',
            dataIndex: 'protocol',
            key: 'protocol',
        },
        {
            title: 'Data',
            dataIndex: 'date',
            key: 'date',
            render: text => convertDate(text)
        },
        {
            title: 'Ações',
            key: 'action',
            render: (_, record) => (
                <Space align='center' size="large">
                    <DownloadOutlined title='Download' onClick={() => downloadFile(record.url)} style={{ fontSize: 24 }} />
                </Space>

            ),
            align: 'center',
        },
    ];
    if (isLoading) {
        return <Spin tip="Carregando..." />;
    }

    return (
        <ContentTable>
            <Title level={3}>Arquivos complementares</Title>
            <Table pagination={false} dataSource={accreditation} columns={columns} />
        </ContentTable>
    );
};


