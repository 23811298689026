import React from 'react';
import {
  Container,
  Content,
  BoxContent,
  ContentCard,
  FooterContainer,
} from './styled';
import { Button } from '../../../components/shered/Button';

type Props = {
  children: React.ReactNode;
  handleClickTemplateButton: () => void;
  handleClickLaboratoryButton: () => void;
};

interface AuthProps extends Props {
  data: {
    title: string;
    titleSecondary: string;
    content: string;
    contentSecondary: string;
    action: string;
    actionSecondary: string;
  };
}

const LayoutAuthSeparations = ({
  children,
  data,
  handleClickTemplateButton,
  handleClickLaboratoryButton,
}: AuthProps) => {
  return (
    <Container>
      <Content>{children}</Content>
      <ContentCard>
        <BoxContent>
          <h1>{data.title}</h1>
          <p>{data.content}</p>
          <Button
            htmlType="button"
            onClick={handleClickTemplateButton}
            type="primary"
          >
            {data.action}
          </Button>
        </BoxContent>

        <BoxContent>
          <h1>{data.titleSecondary}</h1>
          <p>{data.contentSecondary}</p>
          <Button
            htmlType="button"
            onClick={handleClickLaboratoryButton}
            type="primary"
          >
            {data.actionSecondary}
          </Button>
        </BoxContent>
      </ContentCard>
      <FooterContainer>
        <p>
          Desenvolvido por{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://waterservicestech.com/"
          >
            Water Services and Technologies
          </a>
        </p>
      </FooterContainer>
    </Container>
  );
};

export default LayoutAuthSeparations;
