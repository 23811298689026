import styled from 'styled-components';

export const Container = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 513px;
  width: 100%;
  color: #1351b4;
  background: #f8f8f8;
  border: none;
  height: 50px;
  border-radius: 100em;
  padding: 0px 50px;
  font-size: 22px;
  font-family: Rawline, Raleway, sans-serif;
  font-weight: 600;
  transition: all 350ms ease-in-out;
  margin-top: 4rem;

  &:hover {
    color: #1351b4;
    background: linear-gradient(
      rgba(19, 81, 180, 0.16),
      rgba(19, 81, 180, 0.16)
    );
  }

  img {
    width: 4rem;
  }
`;
