import styled from "styled-components";

export const Container = styled.div`
    width:100%;
    max-width:1120px;
    display: flex;   
    justify-content: center; 
`

export const ContentButton = styled.div`
    display: flex; 
    flex-direction: column;  
    button{
        margin-top:20px;
        height: 4rem;
        font-weight: 600;
        font-size: large;
    }
    @media (max-width: 960px) {
        font-weight:400;
        font-size:small;
   }
`