export const defaultTheme = {
  white: '#FFF',

  'green-300': '#00B37E',
  'green-500': '#00875F',
  'green-600': '#256E4F',
  'green-700': '#015F43',
  'Sea Green': '#37A175',
  'gray-light': '#f3f3f3',
  'Dark Slate Gray': '#2B393B',
  'red-500': '#AB222E',
  'red-700': '#7A1921',

  'yellow-500': '#FBA94C',
};
