import React from 'react';
import { Route } from 'react-router-dom';
import { ProcessList } from '../views/app/process/list';
import { UsersLinked } from '../views/app/process/usersLinked';
import { LicensesProcess } from '../views/app/process/licensesProcess';
import { Condition } from '../views/app/process/condition';
import { CyclesList } from '../views/app/process/cycles';
import { ImportConditionList } from '../views/app/process/importConditionList';
import { ImportConditionSteps } from '../views/app/process/importConditionSteps';

const routes = [
  {
    path: '/enterprise/:enterprise_id/:enterprise_name/processos',
    component: <ProcessList title="Processos" />,
    key: 'process',
  },
  {
    path: '/enterprise/:enterprise_id/process/:process_id/usuarios',
    component: <UsersLinked title="Usuários vinculados" />,
    key: 'users',
  },
  {
    path: '/enterprise/:enterprise_name/:enterprise_id/process/:process_id/:main/licencas',
    component: <LicensesProcess title="Licenças" />,
    key: 'licenses',
  },
  {
    path: '/enterprise/:enterprise_name/:enterprise_id/process/:process_id/:main/licencas/:licensing_number/condicionantes',
    component: <Condition title="Condicionantes" />,
    key: 'conditions',
  },
  {
    path: '/enterprise/:enterprise_name/:enterprise_id/process/:process_id/:main/licencas/:licensing_number/condicionantes/:conditionId/ciclos/:id',
    component: <CyclesList title="Ciclos" />,
    key: 'cycles',
  },
  {
    path: '/enterprise/:enterprise_id/process/:process_id',
    component: <ImportConditionList title="Lista pontos e condicionantes" />,
    key: 'import-condition-list',
  },
  {
    path: '/enterprise/:enterprise_id/process/:process_id/import-conditions',
    component: <ImportConditionSteps title="Importação de Condicionantes" />,
    key: 'import-condition-steps',
  },
];

const ProcessRouter: React.FC = () => (
  <>
    {routes.map((route, key) => (
      <Route path={route.path} key={key} exact>
        {route.component}
      </Route>
    ))}
  </>
);

export default ProcessRouter;
