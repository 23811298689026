import React from 'react';

import { Redirect, Route } from 'react-router-dom';
import Login from '../views/auth/Login';

const AuthRouter: React.FC = () => (
  <>
    <Route path="/" exact>
      <Redirect to="/login" />
    </Route>
    <Route path="/login" exact component={Login} />
  </>
);

export default AuthRouter;
