import querystring from 'querystring';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Space, Spin, Table, Modal } from 'antd';
import { toast } from 'react-toastify';
import { CloseOutlined, DownloadOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import Logo from '../../../assets/png/logo_IEMA.png';
import { Content, ContentTable } from './styled';
import LayoutAuthSeparations from '../../../layouts/auth/Separations';
import api from '../../../services/api';
import ButtonSignInGov from '../../../components/layout/ButtonGov';
import { convertDate } from '../../../utils/dateConvert';
import { Drawer } from '../../../components/shered/Drawer';
import { ListAccreditation } from '../../app/laboratory/listAccreditation';
import { Button } from '../../../components/shered/Button';
import { formaterCpfAndCnpj } from '../../../utils/cpfAndCnpjFormater';

interface IPropsTemplate {
  key: React.Key;
  _id: string;
  description: string;
  url: string;
}

interface IPropAcreditation {
  id: number;
  accreditation_code: string;
  start_date: string;
  last_review: string;
  sampling_accreditation: boolean;
  expiration_date: string;
  terms: boolean;
}

const data = {
  title: 'Templates ',
  titleSecondary: 'Laboratórios cadastrados',
  content:
    'Acesse e faça o download dos templates em Excel para envio de dados ao IEMA.',
  contentSecondary:
    'Acesse para visualizar a lista de laboratórios com acreditação ISO 17025 cadastrados no IEMA.',
  action: 'Tenha acesso aos templates',
  actionSecondary: 'Tenha acesso aos Laboratórios',
};

const columnsTemplate: ColumnsType<IPropsTemplate> = [
  {
    title: 'Nome',
    dataIndex: '_id',
    key: '_id',
    width: 300,
  },
  {
    title: 'Data',
    dataIndex: 'lastCreatedAt',
    key: 'lastCreatedAt',
    render: text => convertDate(text),
    width: 150,
    align: 'center',
  },
  {
    title: 'Ações',
    key: 'action',
    render: (_, record) => (
      <Space size="middle">
        <a
          href={`${process.env.REACT_APP_URL_API}/template/download/templates/${record.url}`}
          rel="noopener noreferrer"
          aria-label="Download"
        >
          <DownloadOutlined style={{ fontSize: 24 }} />
        </a>
      </Space>
    ),
    align: 'center',
    width: 150,
  },
];

const Login = () => {
  const [templateList, setTemplateList] = useState<IPropsTemplate[]>([]);
  const [laboratoryList, setLaboratoryList] = useState<IPropAcreditation[]>([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [accreditation, setAcreditation] = useState<any>();
  const [isModalTemplateOpen, setIsModalTemplateOpen] = useState(false);
  const [isModalLaboratoryOpen, setIsModalLaboratoryOpen] = useState(false);
  const [urlGov, setUrlGov] = useState<string>('');
  const { search } = useLocation();

  const showTemplateModal = () => {
    setIsModalTemplateOpen(true);
  };

  const handleCancelTemplateModal = () => {
    setIsModalTemplateOpen(false);
  };

  const showLaboratoryModal = () => {
    setIsModalLaboratoryOpen(true);
  };

  const handleCancelLaboratoryModal = () => {
    setIsModalLaboratoryOpen(false);
  };

  useEffect(() => {
    const sessionExpired = localStorage.getItem('session_expired');
    if (sessionExpired === 'true') {
      localStorage.setItem('session_expired', 'false');
      toast.error('Sessão expirada. Por favor, reconecte.');
    }
    const getUrlLogin = async () => {
      try {
        const loginGov = await api.get(`/auth/cidadao?nonce=${uuidv4()}`);
        setUrlGov(loginGov.data);
      } catch (error) {
        console.error('Ocorreu um erro inesperado: ', error);
      }
    };

    const setLocalStorage = () => {
      const data = querystring.parse(search.replace('?', ''));

      ['refresh_token', 'user', 'token'].forEach(key => {
        if (data[key]) {
          localStorage.setItem(key, data[key].toString());
        }
      });
      if (data.token) {
        window.location.href = '/dashboard';
      }
    };

    const fetchTemplateData = async () => {
      setLoading(true);
      try {
        const responseTemplate = await api.get('/Template');
        const responseLaboratory = await api.get(
          '/enterprise/laboratory/accredited'
        );

        const dataTemplate = responseTemplate.data.map(
          (item: IPropsTemplate) => ({
            ...item,
            key: item._id,
          })
        );

        const dataLaboratory = responseLaboratory.data.map(
          (item: IPropsTemplate) => ({
            ...item,
            key: item._id,
          })
        );

        setTemplateList(dataTemplate);
        setLaboratoryList(dataLaboratory);
      } catch (error) {
        console.error('Ocorreu um erro inesperado: ', error);
      } finally {
        setLoading(false);
      }
    };
    getUrlLogin();
    setLocalStorage();
    fetchTemplateData();
  }, []);

  async function handleClickShowModal(data: any) {
    setIsLoading(true);
    try {
      const laboratoryResponse = await api.get(
        `/enterprise/laboratory/scope/${data}`
      );
      setAcreditation(laboratoryResponse.data);
      setIsModalOpen(true);
    } catch (error) {
      console.error('Ocorreu um erro inesperado: ', error);
    } finally {
      setIsLoading(false);
    }
  }

  const columnsLaboratory: ColumnsType<IPropAcreditation> = [
    {
      title: 'Razão social',
      dataIndex: 'enterprise_name',
      key: 'enterprise_name',
    },
    {
      title: 'CNPJ/CPF',
      dataIndex: 'cpf_cnpj',
      key: 'cpf_cnpj',
      render: text => formaterCpfAndCnpj(text),
    },
    {
      title: 'Cidade / ES',
      dataIndex: 'city',
      key: 'city',
    },
    {
      title: 'Ação',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (_, record) => (
        <Button type="primary" onClick={() => handleClickShowModal(record.id)}>
          Ver acreditação
        </Button>
      ),
    },
  ];

  return (
    <>
      <LayoutAuthSeparations
        handleClickLaboratoryButton={showLaboratoryModal}
        handleClickTemplateButton={showTemplateModal}
        data={data}
      >
        <Content>
          <img src={Logo} alt="Logo iema" />
          <ButtonSignInGov urlGov={urlGov} />
        </Content>
      </LayoutAuthSeparations>
      <Drawer
        title="Lista de Templates"
        size="large"
        closeIcon={false}
        placement="left"
        onClose={handleCancelTemplateModal}
        open={isModalTemplateOpen}
        extra={<CloseOutlined onClick={handleCancelTemplateModal} />}
      >
        <ContentTable>
          {loading ? (
            <Spin tip="Carregando..." size="large">
              <div className="content" />
            </Spin>
          ) : (
            <Table
              pagination={false}
              columns={columnsTemplate}
              dataSource={templateList}
              expandable={{
                expandedRowRender: record => (
                  <p style={{ margin: 0 }}>{record.description}</p>
                ),
                rowExpandable: record => record.description !== '',
              }}
            />
          )}
        </ContentTable>
      </Drawer>
      <Drawer
        title="Lista de Laboratórios"
        size="large"
        closeIcon={false}
        placement="right"
        onClose={handleCancelLaboratoryModal}
        open={isModalLaboratoryOpen}
        extra={<CloseOutlined onClick={handleCancelLaboratoryModal} />}
      >
        <ContentTable>
          {loading ? (
            <Spin tip="Carregando..." size="large">
              <div className="content" />
            </Spin>
          ) : (
            <Table
              pagination={false}
              columns={columnsLaboratory}
              dataSource={laboratoryList}
            />
          )}
        </ContentTable>
      </Drawer>
      <Modal
        width={1000}
        footer={[]}
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
      >
        <ListAccreditation
          isLoading={isLoading}
          accreditation={accreditation}
        />
      </Modal>
    </>
  );
};

export default Login;
