import React from 'react';
import { CardProps } from './interface';
import { Container, Content, CoverContainer, CoverImage } from './styled';

export function CardAction({
  coverItem,
  title,
  description,
  button,
}: CardProps) {
  return (
    <Container>
      <CoverContainer>
        <CoverImage>{coverItem}</CoverImage>
      </CoverContainer>
      <Content>
        <h2>
          <b>{title}</b>
        </h2>
        <p>{description}</p>
        {button}
      </Content>
    </Container>
  );
}
