import React, { useState, useEffect } from 'react';
import { Table, Form, InputNumber, Select, TableColumnsType } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { ContentButton, ContentTable, ContentWarning, Warning } from './styles';
import api from '../../../../../services/api';
import { Button } from '../../../../../components/shered/Button';
import '../../../../../global/tableStyle.css';

const { Option } = Select;

interface ConditionScopeType {
  codigo_ponto: string;
  cod_parametro: string;
  frequencia_monitoramento: number | null;
}

interface DataType {
  key: React.Key;
  condition_scope: ConditionScopeType[];
}

export function FormStep6({ onNext, formData, setFormData, onPrevious }: any) {
  const [loading, setLoading] = useState(true);
  const [refreshKey, setRefreshKey] = useState(0);
  const [parameter, setParameter] = useState<string[]>([]);
  const {
    pontos_selected,
    matriz_monit,
  }: { pontos_selected: string[]; matriz_monit: string } = formData;
  const [newParam, setNewParam] = useState<string | null>(null);
  const [data, setData] = useState<DataType[]>([]);

  const onFinish = (response: any) => {
    const condition_scope: ConditionScopeType[] = [];
    data.forEach(item => {
      item.condition_scope.forEach(scope => {
        condition_scope.push(scope);
      });
    });

    setFormData({ ...formData, ...response, condition_scope });
    onNext();
  };

  const onFinishFailed = (error: any) => {
    console.log('Ocorreu um erro inesperado:', error);
  };

  const handleLegislacaoChange = (
    value: number | null,
    record: DataType,
    point: string
  ) => {
    const newData = [...data];
    const index = newData.findIndex(item => record.key === item.key);
    const item = newData[index];
    const newConditionScope = item.condition_scope.map(scope => {
      if (scope.codigo_ponto === point) {
        return { ...scope, frequencia_monitoramento: value };
      }
      return scope;
    });
    newData.splice(index, 1, { ...item, condition_scope: newConditionScope });
    setData(newData);
  };

  const handleAddRow = () => {
    if (newParam) {
      const alreadyExists = data.some(
        row => row.condition_scope[0].cod_parametro === newParam
      );
      if (!alreadyExists) {
        const newKey = String(data.length + 1);
        const newConditionScope: ConditionScopeType[] = pontos_selected.map(
          point => ({
            codigo_ponto: point,
            cod_parametro: newParam,
            frequencia_monitoramento: null,
          })
        );
        const newRow: DataType = {
          key: newKey,
          condition_scope: newConditionScope,
        };
        setData([...data, newRow]);
      } else {
        toast.warning(`O parâmetro ${newParam} já foi adicionado.`);
      }
      setNewParam(null);
    }
  };

  const handleDeleteRow = (record: DataType) => {
    const newData = data.filter(item => item.key !== record.key);
    setData(newData);
  };

  useEffect(() => {
    setData([]);
  }, [pontos_selected]);

  useEffect(() => {
    const asyncApi = async () => {
      try {
        const respParameter = await api.post('/hga/parameterByMatrix', {
          matrix: matriz_monit,
        });
        const parameterSelect: string[] = [];
        respParameter.data.map((item: any) => {
          parameterSelect.push(item.parameter_code);
        });
        setParameter(parameterSelect);
      } catch (error) {
        console.error('Ocorreu um erro ao buscar os dados: ', error);
      } finally {
        setLoading(false);
      }
    };
    asyncApi();
  }, [matriz_monit, refreshKey]);

  const refreshApiCall = () => {
    setRefreshKey(oldKey => oldKey + 1);
  };

  const columns: TableColumnsType<DataType> = [
    {
      title: 'Parâmetros',
      dataIndex: 'cod_parametro',
      key: 'cod_parametro',
      fixed: 'left',
      render: (_: any, record: DataType) => {
        const param = record.condition_scope[0]?.cod_parametro;
        return <span>{param}</span>;
      },
    },
    ...pontos_selected?.map((point: string) => ({
      title: point,
      dataIndex: point,
      key: point,
      render: (_: any, record: DataType) => {
        const conditionScope = record.condition_scope.find(
          scope => scope.codigo_ponto === point
        );
        const defaultValue = conditionScope?.frequencia_monitoramento ?? 0;
        return (
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item name={record.key.toString()}>
              <InputNumber
                defaultValue={defaultValue}
                onChange={value => handleLegislacaoChange(value, record, point)}
              />
            </Form.Item>
          </Form>
        );
      },
    })),
    {
      title: 'Ação',
      dataIndex: 'action',
      key: 'action',
      render: (_: any, record: DataType) => (
        <Button onClick={() => handleDeleteRow(record)} danger>
          Excluir
        </Button>
      ),
    },
  ];

  const filterOption = (input: string, option: any) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  useEffect(() => {
    setData([]);
    setParameter([]);
  }, [matriz_monit]);

  return (
    <Form
      name="global"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <ContentWarning>
        <Select
          onClick={refreshApiCall}
          loading={loading}
          value={newParam || undefined}
          showSearch
          onChange={value => setNewParam(value)}
          filterOption={filterOption}
          placeholder="Selecione um parâmetro"
        >
          {parameter.map((param, index) => (
            <Option key={index} value={param}>
              {param}
            </Option>
          ))}
        </Select>
        <Button type="primary" onClick={handleAddRow} disabled={!newParam}>
          Adicionar Parâmetro
        </Button>
        <Warning>Frequência de monitoramento em dias</Warning>
      </ContentWarning>
      <ContentTable>
        <Table
          rowClassName="tableBackground"
          dataSource={data}
          pagination={false}
          columns={columns}
          scroll={{ x: 1200, y: 520 }}
        />
      </ContentTable>
      <ContentButton>
        <Button
          type="primary"
          onClick={onPrevious}
          icon={<ArrowLeftOutlined />}
        >
          Anterior
        </Button>
        <Button type="primary" htmlType="submit">
          Próximo
        </Button>
      </ContentButton>
    </Form>
  );
}
