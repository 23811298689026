import React, { useEffect, useState } from 'react';
import { ArrowLeftOutlined, HomeFilled } from '@ant-design/icons';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Title from 'antd/es/typography/Title';
import { Alert, Card, Modal, Spin, Tabs } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import useDocumentTitle from '../../../../hooks/useDocumentTitle';
import LayoutAppCMS from '../../../../layouts/app';
import { PageDescription } from '../../../../components/shered/HeaderCards';
import { Button } from '../../../../components/shered/Button';
import { ContentButton, ContentTable } from './styled';
import { FileUploader } from '../../../../components/shered/FileUploader';
import { StationContext } from '../../../../providers/Upload-context';
import { ErrorObject } from '../../../../types/errorObject';
import { PointViewer } from '../../../../components/shered/PointViewer';
import { FileUploaderCondition } from '../../../../components/shered/FileUploaderCondition';
import api from '../../../../services/api';
import { convertDate } from '../../../../utils/dateConvert';
import { treatmentError } from '../../../../hooks/erros';

interface IFilesProps {
  station?: any;
  condition?: any;
}

interface IPropsProcess {
  key: React.Key;
  _id: string;
  cod_parametro: string;
  codigo_ponto: string;
  legislation: string;
  valor_maximo: string;
  valor_minimo: string;
  value_texto: string;
  frequencia_monitoramento: string;
}

const columns: ColumnsType<IPropsProcess> = [
  {
    title: 'Código do Ponto',
    dataIndex: 'codigo_ponto',
  },
  {
    title: 'Parâmetros',
    dataIndex: 'cod_parametro',
  },
  {
    title: 'Legislações',
    dataIndex: 'legislation',
  },
  {
    title: 'Mínimo',
    dataIndex: 'valor_minimo',
  },
  {
    title: 'Máximo',
    dataIndex: 'valor_maximo',
  },
  {
    title: 'Textual',
    dataIndex: 'valor_textual',
    align: 'center',
  },
  {
    title: 'Frequência de monitoramento',
    dataIndex: 'frequencia_monitoramento',
    align: 'center',
    render: (text: string) => <p>{text} dias</p>,
  },
];

export function ImportConditionSteps({ title }: any) {
  useDocumentTitle(title);
  const history = useHistory();
  const { enterprise_id }: any = useParams();
  const { _name_enterprise, _name_process }: any = useLocation().state;
  const [files, setFiles] = useState<IFilesProps>();
  const [station, setStation] = useState<any>(null);
  const [conditon, setCondition] = useState<any>(null);
  const [tabs, setTabs] = useState([]);
  const [error, setError] = useState<ErrorObject[]>();
  const [openModalStation, setOpenModalStation] = useState(false);
  const [openModalCondition, setOpenModalCondition] = useState(false);
  const [loading, setLoading] = useState(false);
  const [block, setBlock] = useState(true);
  const [step, setStap] = useState(0);

  const showModal = () => {
    setOpenModalStation(true);
  };

  const showModalCondition = () => {
    setOpenModalCondition(true);
  };

  const hideModal = () => {
    setOpenModalStation(false);
    setOpenModalCondition(false);
  };

  const hadleTab = async () => {
    const items: any = [];

    if (conditon) {
      await Promise.all(
        conditon.map(async (item: any, key: any) => {
          let legislation = '';

          item.condition_legislation.map((item: any, key: any) => {
            if (key !== 0) {
              legislation += ', ';
            }
            legislation += item.legislation_id;
          });

          const monitoring_scope: any = [];

          const legs = legislation;
          const legislationArr = legs.split(', ');

          const params: any = [];
          item.monitoring_scope.map(async (item: any) => {
            legislationArr.map(legs => {
              const index = params.findIndex(
                (item: any) => item.legis_code === legs
              );

              if (index < 0) {
                params.push({
                  legis_code: legs,
                  parameter_code: [item.cod_parametro],
                });
              } else {
                params[index].parameter_code.push(item.cod_parametro);
              }
            });
          });

          const { data } = await api.post('/hga/findParamterValue', params);

          await Promise.all(
            item.monitoring_scope.map(async (item: any) => {
              let legs = legislation;

              let min: string = '';
              let max: string = '';
              let text: string = '';

              try {
                legislationArr.map((legs: any, key: number) => {
                  const res = data.find(
                    (dataItem: any) =>
                      dataItem.legis_code === legs &&
                      dataItem.parameter_code === item.cod_parametro
                  );

                  if (key !== 0) {
                    min += ', ';
                    max += ', ';
                    text += ', ';
                  }

                  if (res) {
                    min += res.min_value ? res.min_value : '--';
                    max += res.max_value ? res.max_value : '--';
                    text += res.text_value ? res.text_value : '--';
                  } else {
                    min += '--';
                    max += '--';
                    text += '--';
                  }
                });
              } catch (err) {
                console.log(err);
              }

              if (item.valor_minimo !== null || item.valor_maximo !== null) {
                legs = 'CUSTOMIZADA';
                min = item.valor_minimo;
                max = item.valor_maximo;
                text = item.valor_textual;
              }

              monitoring_scope.push({
                ...item,
                legislation: legs,
                valor_minimo: min,
                valor_maximo: max,
                valor_textual: text,
              });
            })
          );

          items.push({
            key: `${key}`,
            label: `${item.condition_code}`,
            children: (
              <>
                <Card style={{ width: '100%' }}>
                  <p>
                    <b>Tipo de Licença:</b> {item.tipo_licenca}
                  </p>
                  <p>
                    <b>Número da Condicionante:</b> {item.num_condicionante}
                  </p>
                  <p>
                    <b>Versão:</b> {item.versao}
                  </p>
                  <p>
                    <b>Matriz de monitoramento:</b> {item.matriz_monit}
                  </p>
                  <p>
                    <b>Frequência de envio dos dados:</b>{' '}
                    {item.freq_envio_dados}
                  </p>
                  <p>
                    <b>Data de início:</b> {convertDate(item.data_inicio)}
                  </p>
                  <p>
                    <b>Data de fim:</b>{' '}
                    {item.data_fim && convertDate(item.data_fim)}
                  </p>
                  <p>
                    <b>Comentário:</b> {item.comentario}
                  </p>
                </Card>
                <br />
                <Card style={{ width: '100%' }}>
                  <p>
                    <b>Legislações:</b> {legislation}
                  </p>
                </Card>
                <br />
                <div style={{ overflow: 'scroll' }}>
                  <Table
                    pagination={false}
                    columns={columns}
                    dataSource={monitoring_scope}
                  />
                </div>
              </>
            ),
          });
        })
      );
    }

    setTabs(items);
  };

  const handleClickNextStap = () => {
    setStap(step + 1);
    hideModal();
  };

  const handleClickSendFiles = async () => {
    setLoading(true);
    if (files) {
      if (files.station) {
        const formDataStation = new FormData();
        formDataStation.append('file', files?.station.originFileObj);

        try {
          await api.post('/file/station', formDataStation);
          toast.success('Dados de Pontos cadastrados');
        } catch (err) {
          toast.error(`Pontos: ${err.data.message}`);
        }
      }
      if (files.condition) {
        const formDataCondition = new FormData();
        formDataCondition.append('file', files?.condition.originFileObj);

        try {
          await api.post('/file/condition', formDataCondition);
          toast.success('Dados de Condicionantes cadastrados');
          setLoading(false);
          hideModal();
        } catch (err) {
          const error = treatmentError(err.data);
          toast.error(`Condicionantes: ${error}`);
          setLoading(false);
        }
      }
    }
  };

  const handleClickPrevStap = () => {
    if (step === 0) {
      history.goBack();
    } else {
      setStap(step - 1);
    }
  };

  const buttons = [
    <Button
      icon={<ArrowLeftOutlined />}
      size="large"
      onClick={() => handleClickPrevStap()}
    >
      Voltar
    </Button>,
  ];

  const breadcrumb = [
    {
      label: <HomeFilled />,
      link: '/dashboard',
    },
    {
      label: 'Empreendimentos',
      link: '/enterprise',
    },
    {
      label: `${_name_enterprise}`,
    },
    {
      label: 'Processos',
      link: `/enterprise/${enterprise_id}/${_name_enterprise}/processos`,
    },
    {
      label: `${_name_process}`,
    },
    {
      label: 'Importar pontos e condicionantes',
    },
  ];

  const onFileUploadSuccessStations = async (response: any) => {
    toast.success('Arquivo carregado com sucesso.');
    setFiles({ ...files, station: response.file });
    setLoading(false);
  };

  const onFileUploadSuccessCondition = async (response: any) => {
    setFiles({ ...files, condition: response.file });
    setLoading(false);
    setBlock(false);
  };

  const onFileUploadError = (err: any) => {
    const error = treatmentError(err);
    toast.error(error);
  };

  const onFileUploadRemove = () => {
    setLoading(false);
  };

  useEffect(() => {
    hadleTab();
  }, [conditon, station]);

  return (
    <LayoutAppCMS>
      <PageDescription
        description="Importe pontos e condicionantes"
        title={title}
        breadcrumb={breadcrumb}
        buttons={buttons}
      />
      <ContentTable>
        {step === 0 && (
          <>
            <h2>Envio de pontos</h2>
            <StationContext.Provider
              value={{
                station,
                setStation,
                error,
                setError,
                setLoading,
                loading,
              }}
            >
              <FileUploader
                onFileRemove={onFileUploadRemove}
                onFileUploadSuccess={onFileUploadSuccessStations}
                onFileUploadError={onFileUploadError}
                uploadEndpoint={`${process.env.REACT_APP_URL_API}/file/stationValidate`}
                uploadMessage="Envie os arquivos"
                uploadHint="Selecione o arquivo com os dados cadastrais dos pontos"
                isConvertUTMtoWGS
              />
              {station !== null && (
                <>
                  <Title style={{ marginTop: '2rem' }} level={3}>
                    Novos Pontos:
                  </Title>
                  {station.stations.map((item: any, id: number) => (
                    <React.Fragment key={id}>
                      {!item.is_new && (
                        <Alert
                          message="O ponto de monitoramento já foi cadastrado anteriormente, precisa ser excluído do arquivo."
                          type="error"
                          closable
                        />
                      )}
                      <PointViewer
                        styleProp={{
                          background: item.is_new ? 'white' : '#c76969',
                        }}
                        data={item}
                        key={item.id}
                      />
                    </React.Fragment>
                  ))}
                </>
              )}
            </StationContext.Provider>
            <ContentButton>
              <Button
                loading={loading}
                size="large"
                type="primary"
                onClick={showModal}
              >
                Avançar
              </Button>
            </ContentButton>
            <Modal
              title="Confirmação de dados"
              open={openModalStation}
              onOk={handleClickNextStap}
              onCancel={hideModal}
              okText="Sim"
              cancelText="Não"
            >
              <p>Os pontos listados estão corretos?</p>
            </Modal>
          </>
        )}
        {step === 1 && (
          <>
            <h2>Envio de condicionantes</h2>
            <StationContext.Provider
              value={{
                conditon,
                setCondition,
                error,
                setError,
                setLoading,
                loading,
              }}
            >
              <FileUploaderCondition
                onFileRemove={onFileUploadRemove}
                onFileUploadSuccess={onFileUploadSuccessCondition}
                onFileUploadError={onFileUploadError}
                uploadEndpoint={`${process.env.REACT_APP_URL_API}/file/conditionValidateRead`}
                uploadMessage="Envie os arquivos"
                uploadHint="Selecione o arquivo com os dados cadastrais das condicionatens"
              />
            </StationContext.Provider>
            <br />
            <Tabs defaultActiveKey="1" items={tabs} />
            <ContentButton>
              {block ? (
                <Button disabled size="large" type="primary">
                  Avançar
                </Button>
              ) : (
                <Button
                  size="large"
                  type="primary"
                  onClick={showModalCondition}
                  loading={loading}
                >
                  Avançar
                </Button>
              )}
            </ContentButton>
            <Modal
              title="Confirmação de dados"
              open={openModalCondition}
              onOk={handleClickSendFiles}
              onCancel={hideModal}
              okText="Sim"
              cancelText="Não"
            >
              <p>Os dados de todas as condicionantes estão corretos?</p>
              {loading && (
                <Spin tip="Carregando..." size="large">
                  <div className="content" />
                </Spin>
              )}
            </Modal>
          </>
        )}
      </ContentTable>
    </LayoutAppCMS>
  );
}
