import React from 'react';

import { BrowserRouter as Router } from 'react-router-dom';

import AuthRouter from './auth';
import AppRouter from './app';
import EnterpriseRouter from './enterprise';
import ProcessRouter from './process';
import StationRouter from './station';
import TemplatesRouter from './templates';
import LaboratoryRouter from './laboratory';

const Routes: React.FC = () => (
  <Router>
    <AuthRouter />
    <AppRouter />
    <EnterpriseRouter />
    <ProcessRouter />
    <StationRouter />
    <TemplatesRouter />
    <LaboratoryRouter />
  </Router>
);

export default Routes;
