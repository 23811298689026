import React, { useState, useEffect } from 'react';
import { Tabs, Table, Input, Select, Switch, Spin } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from '../../../../../components/shered/Button';
import api from '../../../../../services/api';
import { ContentButton, ContentTable } from './styles';

interface IDropList {
  value: string;
  label: string;
}

interface WaterQualityData {
  cod_parametro: string;
  codigo_ponto: string;
  editable: boolean;
  frequencia_monitoramento: number;
  legislation: string;
  valor_maximo: string;
  valor_minimo: string;
  valor_textual: string;
}

const safeGet = (obj: any, path: string[]) =>
  path.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), obj);

const DEFAULT_STORAGE_VALUE = 'false';
const DEFAULT_STORAGE_DATA = JSON.stringify([]);

export function FormStep7({
  formData,
  setFormData,
  onSaveFormData,
  onNext,
  onPrevious,
  acordionIndex,
}: any) {
  const { condition_scope, cod_legislacao, pontos_selected } = formData;
  const [textualValues, setTextualValues] = useState<IDropList[]>([]);
  const [tabs, setTabs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const updateConditionScope = (
    codigo_ponto: string,
    cod_parametro: string,
    campo: string,
    value: string
  ) => {
    const updatedConditionScope = formData.condition_scope.map(
      (condition: any) => {
        if (
          condition.codigo_ponto === codigo_ponto &&
          condition.cod_parametro === cod_parametro
        ) {
          return { ...condition, [campo]: value };
        }
        return condition;
      }
    );
    setFormData((prevData: any) => ({
      ...prevData,
      condition_scope: updatedConditionScope,
    }));
  };

  const formatValue = (value: string) => {
    return value === 'NA' ? '---' : value;
  };

  const handleSubmit = () => {
    const data = formData.condition_scope?.map((condition: any) => {
      if (!condition.editable) {
        return {
          ...condition,
          valor_maximo: null,
          valor_minimo: null,
          valor_textual: null,
        };
      }
      return condition;
    });
    onSaveFormData({ ...formData, condition_scope: data });
    onNext();
  };

  const paramtersMinAndMax = async () => {
    const newListPoints: any = [];
    const uniquePontoCodes = [
      ...new Set(condition_scope?.map((item: any) => item.codigo_ponto)),
    ];

    await Promise.all(
      uniquePontoCodes.map(async codigo_ponto => {
        const pointBycode = condition_scope.filter(
          (item: any) =>
            item.codigo_ponto === codigo_ponto &&
            item.frequencia_monitoramento !== null
        );
        const legislation = cod_legislacao.join(', ');

        const paramters = pointBycode.map((line: any) => line.cod_parametro);

        const params = legislation
          .split(', ')
          .reduce((acc: any, legis: any) => {
            paramters.forEach((param: any) => {
              const existing = acc.find(
                (item: any) => item.legis_code === legis
              );
              if (existing) {
                existing.parameter_code.push(param);
              } else {
                acc.push({ legis_code: legis, parameter_code: [param] });
              }
            });
            return acc;
          }, []);

        let paramterInBrowser: any[] = [];
        if (localStorage.getItem('existsParamterValue') !== 'true') {
          const { data } = await api.post('/hga/findParamterValue', params);
          paramterInBrowser = [data];
          localStorage.setItem('paramterValue', JSON.stringify(data));
          localStorage.setItem('existsParamterValue', 'true');
        } else {
          const localData = localStorage.getItem('paramterValue');
          if (localData) {
            paramterInBrowser = [JSON.parse(localData)];
          } else {
            localStorage.setItem('existsParamterValue', 'false');
          }
        }

        const newListParameters = paramters.map((param: any) => {
          const matchedPoint = pointBycode.find(
            (item: any) => item.cod_parametro === param
          );

          const minValues = legislation.split(', ').map((legis: any) => {
            const matchedParameter = paramterInBrowser
              .flat()
              .find(
                item =>
                  item.legis_code === legis && item.parameter_code === param
              );
            return safeGet(matchedParameter, ['min_value']) || 'NA';
          });

          const maxValues = legislation.split(', ').map((legis: any) => {
            const matchedParameter = paramterInBrowser
              .flat()
              .find(
                item =>
                  item.legis_code === legis && item.parameter_code === param
              );
            return safeGet(matchedParameter, ['max_value']) || 'NA';
          });

          const textValues = legislation.split(', ').map((legis: any) => {
            const matchedParameter = paramterInBrowser
              .flat()
              .find(
                item =>
                  item.legis_code === legis && item.parameter_code === param
              );
            return safeGet(matchedParameter, ['text_value']) || 'NA';
          });

          return {
            ...matchedPoint,
            valor_maximo: maxValues.join(', '),
            valor_minimo: minValues.join(', '),
            valor_textual: textValues.join(', '),
            legislation,
            editable: matchedPoint.editable || false,
          };
        });

        newListPoints.push({
          codigo_ponto,
          parameters: newListParameters,
        });
      })
    );

    setTabs(newListPoints);
    setIsLoading(false);
  };

  const handleToggleEditable = async (
    codigo_ponto: string,
    cod_parametro: string
  ) => {
    const editIndex = formData.condition_scope.findIndex(
      (condition: any) =>
        condition.codigo_ponto === codigo_ponto &&
        condition.cod_parametro === cod_parametro
    );

    const newParameters: any = [];
    formData.condition_scope.map((parameters: any, key: number) => {
      if (key === editIndex) {
        newParameters.push({ ...parameters, editable: !parameters.editable });
      } else {
        newParameters.push({ ...parameters });
      }
    });
    setFormData({ ...formData, condition_scope: newParameters });
  };

  const handleInputChange = async (
    codigo_ponto: string,
    cod_parametro: string,
    campo: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.target;
    const updatedFormData = { ...formData };
    const updatedConditionScope = updatedFormData.condition_scope.map(
      (condition: any) => {
        if (
          condition.codigo_ponto === codigo_ponto &&
          condition.cod_parametro === cod_parametro
        ) {
          return {
            ...condition,
            [campo]: value,
          };
        }
        return condition;
      }
    );

    updatedFormData.condition_scope = updatedConditionScope;
    setFormData(updatedFormData);
  };

  useEffect(() => {
    paramtersMinAndMax();
  }, [pontos_selected]);

  useEffect(() => {
    const fetchTextualValues = async () => {
      try {
        const respTextualValues = await api.get(
          '/hga/dropdownList/condition_monitoring_scope/text_value'
        );
        const dropListTextualValues: IDropList[] = respTextualValues.data.map(
          (item: string) => ({ value: item, label: item })
        );
        setTextualValues(dropListTextualValues);
      } catch (error) {
        console.error('Ocorreu um erro ao buscar os dados: ', error);
      }
      await paramtersMinAndMax();
    };
    fetchTextualValues();
  }, [formData, pontos_selected]);

  useEffect(() => {
    localStorage.setItem('existsParamterValue', DEFAULT_STORAGE_VALUE);
    localStorage.setItem('paramterValue', DEFAULT_STORAGE_DATA);
  }, []);

  const columns: ColumnProps<WaterQualityData>[] = [
    {
      title: 'Cod Parametro',
      dataIndex: 'cod_parametro',
      key: 'cod_parametro',
      width: 128,
    },
    {
      title: 'Legislação',
      dataIndex: 'legislation',
      key: 'legislation',
      render: (_, record) => (
        <>
          {record.legislation.split(', ').map((item, index) => (
            <>
              <p> &bull; {item}</p>
              <p key={index}>
                Máximo:{' '}
                <strong>
                  {formatValue(record.valor_maximo.split(', ')[index])}&nbsp;
                </strong>
                Mínimo:{' '}
                <strong>
                  {formatValue(record.valor_minimo.split(', ')[index])}
                </strong>
              </p>
            </>
          ))}
        </>
      ),
    },

    {
      title: 'Valor Minimo',
      dataIndex: 'valor_minimo',
      key: 'valor_minimo',
      render: (_, record) => (
        <Input
          disabled={!record.editable}
          style={{ width: '128px' }}
          placeholder="Valor mínimo"
          onChange={e =>
            handleInputChange(
              record.codigo_ponto,
              record.cod_parametro,
              'valor_minimo',
              e
            )
          }
        />
      ),
    },
    {
      title: 'Valor Maximo',
      dataIndex: 'valor_maximo',
      key: 'valor_maximo',
      render: (_, record) => (
        <Input
          disabled={!record.editable}
          style={{ width: '128px' }}
          placeholder="Valor máximo"
          onChange={e =>
            handleInputChange(
              record.codigo_ponto,
              record.cod_parametro,
              'valor_maximo',
              e
            )
          }
        />
      ),
    },
    {
      title: 'Valor Textual',
      dataIndex: 'valor_textual',
      key: 'valor_textual',
      render: (_, record) => (
        <Select
          disabled={!record.editable}
          onChange={value =>
            updateConditionScope(
              record.codigo_ponto,
              record.cod_parametro,
              'valor_textual',
              value
            )
          }
          style={{ width: '128px' }}
        >
          {textualValues.map((opcao, index) => (
            <Select.Option key={index} value={opcao.value}>
              {opcao.label}
            </Select.Option>
          ))}
        </Select>
      ),
      width: 200,
    },
    {
      title: 'Customizar',
      dataIndex: 'editable',
      key: 'editable',
      render: (_, record) => (
        <>
          <Switch
            checked={record.editable}
            onChange={() =>
              handleToggleEditable(record.codigo_ponto, record.cod_parametro)
            }
          />
        </>
      ),
    },
  ];

  return (
    <Spin spinning={isLoading}>
      {acordionIndex >= 5 && (
        <Tabs type="card">
          {tabs?.map((point: any, index) => (
            <Tabs.TabPane tab={`${point.codigo_ponto}`} key={index.toString()}>
              <ContentTable>
                <Table
                  pagination={false}
                  dataSource={point.parameters}
                  columns={columns}
                  rowKey="cod_parametro"
                />
              </ContentTable>
              <ContentButton>
                <Button
                  type="primary"
                  onClick={onPrevious}
                  icon={<ArrowLeftOutlined />}
                >
                  Anterior
                </Button>
                <Button type="primary" onClick={handleSubmit}>
                  Salvar
                </Button>
              </ContentButton>
            </Tabs.TabPane>
          ))}
        </Tabs>
      )}
    </Spin>
  );
}
