export function formaterCpfAndCnpj(data: string) {
    const cleanInput = data.replace(/\D/g, '');
    if (cleanInput.length <= 11) {
        return cleanInput.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    };
    if (cleanInput.length === 14) {
        return cleanInput.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
    };
    return data;
}
