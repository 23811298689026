import React from 'react';
import { Avatar as AvatarAntd } from 'antd';
import { Container, Description } from './styled';
import { AvatarProps } from './interface';

export function Avatar({ avatarImage, name, responsibility }: AvatarProps) {
  return (
    <Container>
      <Description>
        <h4>{name}</h4>
        <h5>{responsibility}</h5>
      </Description>
      <AvatarAntd
        style={{ marginLeft: '0.5rem' }}
        size="large"
        icon={avatarImage}
      />
    </Container>
  );
}
