import React from 'react';
import { Route } from 'react-router-dom';
import RequireAuth from '../auth/Auth';
import Dashboard from '../views/app/dashboard';

const routes = [
  {
    path: '/dashboard',
    component: <Dashboard title="Dashboad" />,
    key: 'dashboard',
  },
];

const AppRouter: React.FC = () => (
  <>
    {routes.map((route, key) => (
      <RequireAuth key={key}>
        <Route path={route.path} exact>
          {route.component}
        </Route>
      </RequireAuth>
    ))}
  </>
);

export default AppRouter;
