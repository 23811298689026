import React from 'react';
import { ArrowLeftOutlined, HomeFilled } from '@ant-design/icons';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { useParams, useLocation } from 'react-router-dom';
import useDocumentTitle from '../../../../../hooks/useDocumentTitle';
import LayoutAppCMS from '../../../../../layouts/app';
import { PageDescription } from '../../../../../components/shered/HeaderCards';
import { Button } from '../../../../../components/shered/Button';
import { ContentButton, ContentTable } from './styles';
import api from '../../../../../services/api';
import '../../../../../global/tableStyle.css'

interface IPropsConditions {
  key: React.Key,
  um_condicionante: string,
  tipo_licenca: string,
  matriz_monit: string,
  data_inicio: any,
  data_fim: any,
}

export function FormStep2({ onNext, onBack, allData, setFormData, setStep, setShowAcordion, setAllData, SetConditionsResponse }: any) {
  useDocumentTitle('Lista condicionantes');
  const location = useLocation();
  const { name_enterprise, name_process }: any = location.state
  const { enterprise_id, process_id }: any = useParams();

  const handleDelete = (key: React.Key) => {
    const updatedData = allData.filter((item: any) => {
      return item.key !== key
    });
    setAllData(updatedData);
  };

  const breadcrumb = [
    {
      label: <HomeFilled />,
      link: '/dashboard',
    },
    {
      label: 'Empreendimentos',
      link: '/enterprise',
    },
    {
      label: `${name_enterprise}`,
    },
    {
      label: 'Processos',
      link: `/enterprise/${enterprise_id}/${name_enterprise}/processos`,
    },
    {
      label: `${name_process}`,
    },
    {
      label: 'Pontos e condicionantes',
    },
  ];


  const columns: ColumnsType<IPropsConditions> = [
    {
      title: 'Número da condicionante',
      dataIndex: 'um_condicionante',
      key: 'um_condicionante',
    },
    {
      title: 'Tipo Licenca',
      dataIndex: 'tipo_licenca',
      key: 'tipo_licenca',
    },
    {
      title: 'Matriz',
      dataIndex: 'matriz_monit',
      key: 'matriz_monit',
    },
    {
      title: 'Data Início',
      dataIndex: 'data_inicio',
      key: 'data_inicio',
    },
    {
      title: 'Data Fim',
      dataIndex: 'data_fim',
      key: 'data_fim',
    },
    {
      title: 'Ações',
      dataIndex: 'actions',
      key: 'actions',
      render: (_: any, record: IPropsConditions) => {
        return (
          <Button
            danger
            onClick={() => handleDelete(record.key)}
          > Excluir </Button>
        );
      },
    }
  ];

  const dataSource: IPropsConditions[] = allData?.map((item: IPropsConditions) => ({
    key: item?.key,
    um_condicionante: item?.um_condicionante,
    tipo_licenca: item?.tipo_licenca,
    matriz_monit: item?.matriz_monit,
    data_inicio: item?.data_inicio && moment(new Date(item?.data_inicio.$y, item?.data_inicio.$M, item?.data_inicio.$D, item?.data_inicio.$H, item?.data_inicio.$m, item?.data_inicio.$s)).format("DD/MM/YYYY"),
    data_fim: item?.data_fim && moment(new Date(item?.data_fim.$y, item?.data_fim.$M, item?.data_fim.$D, item?.data_fim.$H, item?.data_fim.$m, item?.data_fim.$s)).format("DD/MM/YYYY"),
  }))

  const handleNewCondition = () => {
    onNext()
    setFormData((prevState: any) => ({
      stationPhysicalMedium: prevState.stationPhysicalMedium,
    }));
    setStep(2)
    setShowAcordion(1)
  }

  const handleSubmit = async () => {
    setStep(4)
    const dataSource: any = allData?.map((item: any) => ({
      processo_id: `${process_id}`,
      tipo_licenca: item.tipo_licenca,
      num_condicionante: item.um_condicionante,
      versao: item.versao,
      matriz_monit: item.matriz_monit,
      freq_envio_dados: item.freq_envio_dados,
      data_inicio: item?.data_inicio && moment(new Date(item?.data_inicio.$y, item?.data_inicio.$M, item?.data_inicio.$D, item?.data_inicio.$H, item?.data_inicio.$m, item?.data_inicio.$s)).format("DD/MM/YYYY"),
      data_fim: item?.data_fim && moment(new Date(item?.data_fim.$y, item?.data_fim.$M, item?.data_fim.$D, item?.data_fim.$H, item?.data_fim.$m, item?.data_fim.$s)).format("DD/MM/YYYY"),
      comentario: item.comentario,
      condition_legislation: item.cod_legislacao.map((legis: string) => ({ cod_legislacao: legis })),
      condition_scope: item.condition_scope.filter((item: any) => item.frequencia_monitoramento !== null),
    }))
    const response = await api.post('/file/conditionValidate', dataSource)
    await SetConditionsResponse(response.data)

  }

  const buttons = [
    <Button icon={<ArrowLeftOutlined />} onClick={onBack} size="large">
      Voltar
    </Button>
    ,
    <Button type='primary' onClick={handleNewCondition} size="large">
      Nova Condicionante
    </Button>,
  ];
  return (
    <LayoutAppCMS>
      <PageDescription
        description="Visualize suas condicionantes criadas"
        title='Lista de condicionantes'
        breadcrumb={breadcrumb}
        buttons={buttons}
      />
      <ContentTable>
        <Table rowClassName='tableBackground' pagination={false} columns={columns} dataSource={dataSource} />
      </ContentTable>
      <ContentButton>
        {allData?.length > 0 &&
          <Button type='primary' onClick={handleSubmit} size="large">
            Avançar
          </Button>}
      </ContentButton>
    </LayoutAppCMS>
  );
}
