import React, { useEffect, useState } from 'react';
import { TitleProps } from 'antd/es/typography/Title';
import { Link, useHistory } from 'react-router-dom';
import { Skeleton } from 'antd';
import { toast } from 'react-toastify';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import LayoutAppCMS from '../../../layouts/app';
import { Content, CardSkeleton } from './styles';
import { Button } from '../../../components/shered/Button';
import { CardAction } from '../../../components/layout/CardAction';
import api from '../../../services/api';
import { logout } from '../../../utils/logout';
import { validAccess } from '../../../hooks/roles';
import { Modal } from '../../../components/shered/Modal';
import { treatmentError } from '../../../hooks/erros';

interface ICucaErrosProps {
  erros: string[];
}

interface ICucaMensageProps {
  message: ICucaErrosProps;
}

interface ICucaProps {
  data: ICucaMensageProps;
}

function Dashboard({ title }: TitleProps) {
  useDocumentTitle(title);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contentModal, setContentModal] = useState<string>();

  const [visibleTemplate, setVisibleTemplate] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);

  const history = useHistory();
  useEffect(() => {
    const AsyncApi = async () => {
      setVisibleTemplate(await validAccess('Templates'));
      setLoadingPage(false);
    };
    AsyncApi();
  }, []);

  const showModal = ({ data }: ICucaProps) => {
    let erros = '';

    data?.message?.erros?.map((err: string) => {
      erros += `<li>${err}</li>`;
    });

    setContentModal(`<ul>${erros}</ul>`);

    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setLoading(false);
    history.push('/enterprise');
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setLoading(false);
  };

  const handleClickLoadingEnterprise = async () => {
    try {
      await api.post('/enterprise');
      setLoading(false);
      history.push('/enterprise');
    } catch (error) {
      const err = treatmentError(error.data.message);
      setLoading(false);
      if (error?.status === 404) {
        showModal({ data: error.data.message });
        toast.error(err);
      } else if (error?.status === 401) {
        logout(history);
        toast.error(err);
      } else {
        toast.error('Erro não tratado');
      }
    }
  };

  return (
    <LayoutAppCMS>
      <Content>
        {!loadingPage ? (
          <>
            <CardAction
              title="Empreendimentos"
              description="Adicione e visualize a lista de empreendimentos cadastrados no IEMA."
              coverItem={
                <>
                  <img src="./img/empreendimento.png" alt="Empreedimentos" />
                </>
              }
              button={
                <Button
                  onClick={handleClickLoadingEnterprise}
                  type="primary"
                  size="large"
                  block
                  loading={loading}
                >
                  Acessar
                </Button>
              }
            />
            <CardAction
              title="Laboratórios"
              description="Lista de laboratórios com acreditação ISO17025 cadastrados no IEMA"
              coverItem={
                <>
                  <img src="./img/laboratorio.png" alt="Empreedimentos" />
                </>
              }
              button={
                <Link to="/laboratorio" style={{ width: '100%' }}>
                  <Button type="primary" size="large" block>
                    Acessar
                  </Button>
                </Link>
              }
            />
            {visibleTemplate && (
              <CardAction
                title="Templates"
                description="Acesse para visualizar a lista de templates disponíveis."
                coverItem={
                  <>
                    <img src="./img/excel.png" alt="Empreedimentos" />
                  </>
                }
                button={
                  <Link to="/templates" style={{ width: '100%' }}>
                    <Button type="primary" size="large" block>
                      Acessar
                    </Button>
                  </Link>
                }
              />
            )}
          </>
        ) : (
          <>
            <CardSkeleton>
              <Skeleton active />
            </CardSkeleton>
            <CardSkeleton>
              <Skeleton active />
            </CardSkeleton>
            <CardSkeleton>
              <Skeleton active />
            </CardSkeleton>
          </>
        )}
      </Content>
      <Modal
        title="CUCA - Cadastro único de clientes ambientais"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width="100%"
        style={{ maxWidth: 800, maxHeight: 450, overflow: 'auto' }}
      >
        {contentModal && (
          <>
            <div
              dangerouslySetInnerHTML={{ __html: contentModal }}
              style={{ display: 'flex', alignItems: 'center', padding: 30 }}
            />
            {process.env.REACT_APP_URL_CUCA && (
              <a
                href={process.env.REACT_APP_URL_CUCA}
                target="_blank"
                rel="noreferrer"
              >
                <Button type="primary" size="large" block>
                  Acessar CUCA
                </Button>
              </a>
            )}
          </>
        )}
      </Modal>
    </LayoutAppCMS>
  );
}

export default Dashboard;
