import { useEffect } from 'react';

function useDocumentTitle(title: string | undefined) {

  useEffect(() => {
    if (title) {
      document.title = title;
    }
    return () => {
      document.title = 'Portal IEMA';
    };
  }, [title]);
}

export default useDocumentTitle;
