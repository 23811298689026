import React from 'react';
import { Route } from 'react-router-dom';
import { StationList } from '../views/app/station/list';
import { MultiStepForm } from '../views/app/station/formStation/multiStepForm.tsx';
import { PointCharacterization } from '../views/app/station/pointCharacterization';

const routes = [
  {
    path: '/enterprise/:enterprise_id/:enterprise_name/Station',
    component: <StationList title="Pontos de monitoramento" />,
    key: 'station',
  },
  {
    path: '/enterprise/:enterprise_id/process/:process_id/:Station/create',
    component: <MultiStepForm title="Criar pontos e condicionantes" />,
    key: 'station_create',
  },
  {
    path: '/enterprise/:enterprise_id/:enterprise_name/Station/caracterizacao',
    component: <PointCharacterization title="Caracterização de ponto" />,
    key: 'station',
  },
];

const StationRouter: React.FC = () => (
  <>
    {routes.map((route, key) => (
      <Route path={route.path} key={key} exact>
        {route.component}
      </Route>
    ))}
  </>
);

export default StationRouter;
