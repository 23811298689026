import React, { ReactNode } from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

import { Container } from './styled';

interface BreadcrumbItem {
  label: string | ReactNode;
  link?: string;
}

interface Props {
  data: BreadcrumbItem[];
}

const BreadcrumbBox = ({ data }: Props) => {
  return (
    <Container>
      <Breadcrumb>
        {data.map((item, key) => (
          <Breadcrumb.Item key={key}>
            {item.link ? <Link to={item.link}>{item.label}</Link> : item.label}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </Container>
  );
};

export { BreadcrumbBox };
