import React from 'react';
import { Route } from 'react-router-dom';

import { TemplateList } from '../views/app/templatesView/list';
import { TemplateCreate } from '../views/app/templatesView/create';

const routes = [
  {
    path: '/templates',
    component: <TemplateList title="Lista de templates" />,
    key: 'templates',
  },
  {
    path: '/templates/novo-Template',
    component: <TemplateCreate title="Novo Template" />,
    key: 'TemplateCreate',
  },
];

const TemplatesRouter: React.FC = () => (
  <>
    {routes.map((route, key) => (
      <Route path={route.path} key={key} exact>
        {route.component}
      </Route>
    ))}
  </>
);

export default TemplatesRouter;
