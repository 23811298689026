import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Space, Spin, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
  ArrowLeftOutlined,
  BankOutlined,
  HomeFilled,
  SearchOutlined,
} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import useDocumentTitle from '../../../../hooks/useDocumentTitle';
import LayoutAppCMS from '../../../../layouts/app';
import { PageDescription } from '../../../../components/shered/HeaderCards';
import { Button } from '../../../../components/shered/Button';
import { ContentTable, FilterContainer, SpaceButton } from './styles';
import api from '../../../../services/api';
import { formaterCpfAndCnpj } from '../../../../utils/cpfAndCnpjFormater';
import { validAccess } from '../../../../hooks/roles';
import { TitleProps } from '../../../../global/interfaces/TitleProps';
import { Input } from '../../../../components/shered/Input';

const breadcrumb = [
  {
    label: <HomeFilled />,
    link: '/dashboard',
  },
  {
    label: 'Empreendimentos',
  },
];
interface IPropsEnterprise {
  key: React.Key;
  _id: string;
  cpf_cnpj: string;
  enterprise_name: string;
}

function Enterprise({ title }: TitleProps) {
  useDocumentTitle(title);
  const history = useHistory();
  const [enterprise, setEnterprise] = useState<IPropsEnterprise[]>([]);
  const [loading, setLoading] = useState(true);
  const [
    visibleBuscarEmpreendimento,
    setVisibleBuscarEmpreendimento,
  ] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<any>(null);

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
    setSearchText('');
    setSearchedColumn('');
  };

  const getColumnSearchProps = (dataIndex: any, columnTitle: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <FilterContainer>
        <Input
          ref={searchInput}
          placeholder={`Busque ${columnTitle}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </Space>
      </FilterContainer>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value: any, record: any) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownOpenChange: (visible: any) => {
      if (visible && searchInput.current !== null) {
        searchInput.current.select();
      }
    },
    render: (text: any) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const buttons = [
    <Link to="/dashboard">
      <Button icon={<ArrowLeftOutlined />} size="large">
        Voltar
      </Button>
    </Link>,
    <>
      {visibleBuscarEmpreendimento && (
        <Link to="/enterprise/create">
          <Button size="large" icon={<BankOutlined />}>
            Buscar empreendimento
          </Button>
        </Link>
      )}
    </>,
  ];

  const columns: ColumnsType<IPropsEnterprise> = [
    {
      title: 'Razão social',
      dataIndex: 'enterprise_name',
      ...getColumnSearchProps('enterprise_name', 'Razão social'),
    },
    {
      title: 'CPF / CNPJ',
      dataIndex: 'cpf_cnpj',
      ...getColumnSearchProps('cpf_cnpj', 'CPF / CNPJ'),
      render: text => formaterCpfAndCnpj(text),
      width: 400,
    },
  ];

  useEffect(() => {
    const AsyncApi = async () => {
      try {
        const enterpriseData = await api.get('/enterprise');
        const data: IPropsEnterprise[] = [];
        enterpriseData.data.map((item: IPropsEnterprise) => {
          const dataItem = {
            ...item,
            key: item._id,
          };

          data.push(dataItem);
        });
        setEnterprise(data);
        setLoading(false);
        setVisibleBuscarEmpreendimento(
          await validAccess('Buscar empreendimento')
        );
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    AsyncApi();
  }, []);

  const handleAcessToken = async (enterpriseId: string, path: string) => {
    const enterpriseAccessToken = await api.post(`/auth/access-token`, {
      refresh_token: localStorage.getItem('refresh_token'),
      enterprise_id: enterpriseId,
    });

    const { token, refresh_token } = enterpriseAccessToken.data;
    if (refresh_token) {
      localStorage.setItem('refresh_token', refresh_token.toString());
    }
    if (token) {
      localStorage.setItem('token', token.toString());
      history.push(path);
    }
  };

  return (
    <LayoutAppCMS>
      <PageDescription
        description="Adicione e monitore seus empreendimentos"
        title={title}
        breadcrumb={breadcrumb}
        buttons={buttons}
      />
      <ContentTable>
        {loading ? (
          <Spin tip="Carregando..." size="large">
            <div className="content" />
          </Spin>
        ) : (
          <Table
            pagination={false}
            columns={columns}
            expandable={{
              expandedRowRender: record => (
                <SpaceButton>
                  <Button
                    onClick={() =>
                      handleAcessToken(
                        record._id,
                        `enterprise/${record._id}/${record.enterprise_name}/processos`
                      )
                    }
                    type="primary"
                  >
                    Processos
                  </Button>
                  <Button
                    type="primary"
                    onClick={() =>
                      handleAcessToken(
                        record._id,
                        `enterprise/${record._id}/${record.enterprise_name}/station`
                      )
                    }
                  >
                    Pontos de monitoramento
                  </Button>
                </SpaceButton>
              ),
            }}
            dataSource={enterprise}
          />
        )}
      </ContentTable>
    </LayoutAppCMS>
  );
}

export { Enterprise };
