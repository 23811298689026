import styled from 'styled-components';

export const Container = styled.div`
  color: ${props => props.theme['green-600']};
  display: flex;
  align-items: center;
  text-align: end;
`;

export const Description = styled.div`
  h2 {
    font-weight: 700;
    margin-bottom: -0.3rem;
  }
  @media (max-width: 960px) {
    display: none;
  }
`;
