/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
// eslint-disable-next-line no-unused-vars
import { Dropdown, MenuProps, Space } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import Logo from '../../../assets/png/logo_60x32.png';
import { Container, Content, UserPanel } from './styled';
import { Avatar } from '../Avatar';
import { Notification } from '../Notification';

interface IUser {
  cpf: string;
  email: string;
  name: string;
  _id: string;
}

function Header() {
  const [logout, setLogout] = useState<Boolean>(false);
  const user = localStorage.getItem('user');
  const parsedUser: IUser = JSON.parse(user ?? '');

  const handleLogout = () => {
    localStorage.removeItem('token');
    setLogout(true);
  };
  const items: MenuProps['items'] = [
    // {
    //   key: '1',
    //   label: (
    //     <a
    //       target="_blank"
    //       rel="noopener noreferrer"
    //       href="https://iema.es.gov.br/"
    //     >
    //       Suporte
    //     </a>
    //   ),
    // },
    {
      key: '2',
      label: 'Sair',
      danger: true,
      onClick: () => handleLogout(),
    },
  ];

  return logout ? (
    <Redirect to="/login" />
  ) : (
    <Container>
      <Content>
        <div>
          <img src={Logo} alt="" />
          <div>
            <h1>SIMPA/ES</h1>
            <h5>
              Sistema de Monitoramento de Parâmetros Ambientais do Espírito Santo
            </h5>
          </div>
        </div>
        <UserPanel>
          <Notification />
          <Dropdown trigger={['click']} menu={{ items }}>
            <a onClick={e => e.preventDefault()} aria-hidden="true">
              <Space>
                <Avatar
                  avatarImage={<UserOutlined />}
                  name={parsedUser.name}
                  responsibility={parsedUser.email}
                />
              </Space>
            </a>
          </Dropdown>
        </UserPanel>
      </Content>
    </Container>
  );
}

export default Header;
