import React, { useEffect, useState } from 'react';
import { ArrowLeftOutlined, HomeFilled } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { Spin, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { ContentTable } from './styles';
import api from '../../../../services/api';
import { Button } from '../../../../components/shered/Button';
import { PageDescription } from '../../../../components/shered/HeaderCards';
import LayoutAppCMS from '../../../../layouts/app';
import useDocumentTitle from '../../../../hooks/useDocumentTitle';
import { TitleProps } from '../../../../global/interfaces/TitleProps';
import { formaterCpfAndCnpj } from '../../../../utils/cpfAndCnpjFormater';
import '../../../../global/tableStyle.css';

interface IPropsLaboratory {
  _id: string;
  cpf_cnpj: string;
  enterprise_name: string;
  city: string;
  hga_id: number;
  is_laboratory: boolean;
  schema_version: number;
  tenant_id: string;
  delete: boolean;
  deleted_at: Date | null;
  created_at: Date;
  updated_at: Date;
  __v: number;
}

const breadcrumb = [
  {
    label: <HomeFilled />,
    link: '/dashboard',
  },
  {
    label: 'Laboratórios',
  },
];

export function LaboratoryList({ title }: TitleProps) {
  useDocumentTitle(title);
  const history = useHistory();
  const [laboratory, setLaboratory] = useState<IPropsLaboratory[]>([]);
  const [loading, setLoading] = useState(true);

  const buttons = [
    <Button
      onClick={() => history.goBack()}
      icon={<ArrowLeftOutlined />}
      size="large"
    >
      Voltar
    </Button>,
  ];

  useEffect(() => {
    const AsyncApi = async () => {
      await api.post('/enterprise');
      const laboratoryResponse = await api.get(`/enterprise/laboratory`);
      setLaboratory(laboratoryResponse.data);
      setLoading(false);
    };
    AsyncApi();
  }, []);

  const handleAcessToken = async (enterpriseId: string, path: string) => {
    const enterpriseAccessToken = await api.post(`/auth/access-token`, {
      refresh_token: localStorage.getItem('refresh_token'),
      enterprise_id: enterpriseId,
    });
    const { token, refresh_token } = enterpriseAccessToken.data;
    if (refresh_token) {
      localStorage.setItem('refresh_token', refresh_token.toString());
    }
    if (token) {
      localStorage.setItem('token', token.toString());

      history.push(path);
    }
  };

  const columns: ColumnsType<IPropsLaboratory> = [
    {
      title: 'Razão social',
      dataIndex: 'enterprise_name',
      key: 'enterprise_name',
    },
    {
      title: 'CNPJ/CPF',
      dataIndex: 'cpf_cnpj',
      key: 'cpf_cnpj',
      render: text => formaterCpfAndCnpj(text),
    },
    {
      title: 'Cidade / ES',
      dataIndex: 'city',
      key: 'city',
    },
    {
      title: 'Ação',
      dataIndex: '_id',
      key: '_id',
      render: (_, record) => (
        <Button
          type="primary"
          onClick={() =>
            handleAcessToken(
              record._id,
              `/laboratorio/${record._id}/${record.hga_id}/${record.enterprise_name}/acreditacao`
            )
          }
        >
          Acessar acreditação
        </Button>
      ),
      align: 'center',
    },
  ];

  return (
    <LayoutAppCMS>
      <PageDescription
        description="Monitore os laboratórios"
        title={title}
        breadcrumb={breadcrumb}
        buttons={buttons}
      />
      <ContentTable>
        {loading ? (
          <Spin tip="Carregando..." size="large">
            <div className="content" />
          </Spin>
        ) : (
          <Table
            rowClassName="tableBackground"
            pagination={false}
            dataSource={laboratory}
            columns={columns}
          />
        )}
      </ContentTable>
    </LayoutAppCMS>
  );
}
