import React from 'react';
import { Redirect } from 'react-router-dom';

class RequireAuth extends React.Component<any, { auth: boolean }> {
  constructor(props: any) {
    super(props);
    this.state = { auth: this.getToken() };
  }

  getToken = () => {
    const token = localStorage.getItem('token');
    return !!token;
  };

  render() {
    const { auth } = this.state;
    const { children } = this.props;
    return auth ? <>{children}</> : <Redirect to="/login" />;
  }
}

export default RequireAuth;
