import React, { useEffect, useState } from 'react';
import { ArrowLeftOutlined, HomeFilled } from '@ant-design/icons';
import { useHistory, Link, useParams } from 'react-router-dom';
import { Spin } from 'antd';
import api from '../../../../services/api';
import { Button } from '../../../../components/shered/Button';
import { PageDescription } from '../../../../components/shered/HeaderCards';
import LayoutAppCMS from '../../../../layouts/app';
import useDocumentTitle from '../../../../hooks/useDocumentTitle';
import { TitleProps } from '../../../../global/interfaces/TitleProps';
import { ListAccreditation } from '../listAccreditation';
import { validAccess } from '../../../../hooks/roles';

interface IPropsParameters {
  parameter_code: string;
  matrix: string;
}

interface IPropsAcreditation {
  accreditation_code: string;
  start_date: string;
  last_review: string;
  sampling_accreditation: boolean;
  terms_of_acceptance: string;
  expiration_date: string;
  parameters: IPropsParameters[];
}

export function CharacterizationList({ title }: TitleProps) {
  useDocumentTitle(title);
  const history = useHistory();
  const { laboratory_name }: any = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const { laboratory_id, hga_id }: any = useParams();
  const [accreditation, setAcreditation] = useState<IPropsAcreditation>();
  const codeAccreditation = accreditation?.accreditation_code;

  const [editarAcreditacao, setEditarAcreditacao] = useState(false);
  const [novaAcreditacao, setNovaAcreditacao] = useState(false);

  useEffect(() => {
    const AsyncApi = async () => {
      setEditarAcreditacao(await validAccess('Editar acreditação'));
      setNovaAcreditacao(await validAccess('Nova acreditação'));

      try {
        const laboratoryResponse = await api.get(
          `/enterprise/laboratory/scope/${hga_id}`
        );
        setAcreditation(laboratoryResponse.data);
        setIsLoading(false);
      } catch (error) {
        console.error('Failed to fetch accreditations:', error);
      }
    };

    AsyncApi();
  }, []);

  const buttons = [
    <Button
      onClick={() => history.goBack()}
      icon={<ArrowLeftOutlined />}
      size="large"
    >
      Voltar
    </Button>,
    accreditation ? (
      <>
        {editarAcreditacao && (
          <Link
            to={{
              pathname: `/laboratorio/${laboratory_id}/${hga_id}/acreditacao/edicao`,
              state: {
                laboratoryName: laboratory_name,
                code: codeAccreditation,
              },
            }}
          >
            <Button type="primary" size="large">
              Editar acreditação
            </Button>
          </Link>
        )}
      </>
    ) : (
      <>
        {novaAcreditacao && (
          <Link
            to={{
              pathname: `/laboratorio/${laboratory_id}/${hga_id}/acreditacao/cadastro`,
              state: {
                laboratoryName: laboratory_name,
              },
            }}
          >
            <Button type="primary" size="large">
              Nova acreditação
            </Button>
          </Link>
        )}
      </>
    ),
  ];

  const breadcrumb = [
    {
      label: <HomeFilled />,
      link: '/dashboard',
    },
    {
      label: 'Laboratórios',
      link: '/laboratorio',
    },
    {
      label: `${laboratory_name}`,
    },
    {
      label: 'Acreditação',
    },
  ];

  return (
    <LayoutAppCMS>
      <PageDescription
        description="Adicione e monitore sua acreditação"
        title={title}
        breadcrumb={breadcrumb}
        buttons={buttons}
      />
      {isLoading ? (
        <Spin tip="Carregando..." />
      ) : (
        accreditation && (
          <ListAccreditation
            accreditation={accreditation}
            isLoading={isLoading}
          />
        )
      )}
    </LayoutAppCMS>
  );
}
