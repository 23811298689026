import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;
  align-items: flex-start;
  padding-bottom: 20px;
`;

export const ContentButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
