import React, { useState, useRef } from 'react';
import { Input, Button, Space, Table, Spin, Card } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { ContentTable } from './styles';
import { convertDate } from '../../../../utils/dateConvert';

interface IPropsParameters {
  parameter_code: string;
  matrix: string;
}

interface IPropsAcreditation {
  accreditation_code: string;
  start_date: string;
  last_review: string;
  sampling_accreditation: boolean;
  expiration_date: string;
  terms_of_acceptance: string;
  parameters: IPropsParameters[];
}
interface IListAccreditationProps {
  accreditation: IPropsAcreditation;
  isLoading: boolean;
}

export const ListAccreditation: React.FC<IListAccreditationProps> = ({
  accreditation,
  isLoading,
}) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<any>(null);

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
    setSearchText('');
    setSearchedColumn('');
  };

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Busque ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value: any, record: any) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownOpenChange: (visible: any) => {
      if (visible && searchInput.current !== null) {
        searchInput.current.select();
      }
    },
    render: (text: any) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const columns: ColumnsType<IPropsParameters> = [
    {
      title: 'Parâmetro',
      dataIndex: 'parameter_code',
      key: 'parameter_code',
      ...getColumnSearchProps('parameter_code'),
    },
    {
      title: 'Matriz',
      dataIndex: 'matrix',
      key: 'matrix',
      ...getColumnSearchProps('matrix'),
    },
  ];
  return isLoading ? (
    <Spin tip="Carregando..." />
  ) : (
    <>
      {accreditation && (
        <Card style={{ width: '100%', marginTop: 20 }}>
          <p>
            <b>Código de acreditação:</b> {accreditation.accreditation_code}
          </p>
          <p>
            <b>Data de acreditação:</b> {convertDate(accreditation.start_date)}
          </p>
          <p>
            <b>Data da Última revisão:</b>{' '}
            {convertDate(accreditation.last_review)}
          </p>
          <p>
            <b>Data de expiração:</b>{' '}
            {convertDate(accreditation.expiration_date)}
          </p>
          <p>
            <b>Acreditacão para coleta:</b>{' '}
            {accreditation.sampling_accreditation ? 'Sim' : 'Não'}
          </p>
          <p>
            <b>Horário de aceite do termo:</b>{' '}
            {dayjs(accreditation.terms_of_acceptance).format(
              'DD/MM/YYYY HH:mm:ss'
            )}
          </p>
        </Card>
      )}
      <ContentTable>
        <Table
          pagination={false}
          dataSource={accreditation?.parameters}
          columns={columns}
        />
      </ContentTable>
    </>
  );
};
