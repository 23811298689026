import React, { useState } from 'react';
import {
  ArrowLeftOutlined,
  HomeFilled,
  UploadOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Upload, Form, FormInstance, Select } from 'antd';
import { toast } from 'react-toastify';
import { TitleProps } from '../../../../global/interfaces/TitleProps';
import useDocumentTitle from '../../../../hooks/useDocumentTitle';
import LayoutAppCMS from '../../../../layouts/app';
import { PageDescription } from '../../../../components/shered/HeaderCards';
import { Button } from '../../../../components/shered/Button';
import { Container, Content, ContentButton } from './styles';
import { Input } from '../../../../components/shered/Input';
import api from '../../../../services/api';

interface IDataFileValue {
  name: string;
  url: string;
  folder: string;
  type: 'others' | 'users' | 'templates';
  protocol: string;
  date: string;
}
interface ITemplateValues {
  name: string;
  description: string;
  file: IDataFileValue[];
  type:
    | 'Monitoramento'
    | 'Pontos'
    | 'Poços e Sondagens'
    | 'Acreditação de Laboratorio'
    | 'Parâmetros IEMA';
}

const breadcrumb = [
  {
    label: <HomeFilled />,
    link: '/dashboard',
  },
  {
    label: 'Templates',
    link: '/templates',
  },
  {
    label: 'Novo Template',
  },
];

const buttons = [
  <Link to="/templates">
    <Button icon={<ArrowLeftOutlined />} size="large">
      Voltar
    </Button>
  </Link>,
];

export function TemplateCreate({ title }: TitleProps) {
  useDocumentTitle(title);
  const formRef = React.useRef<FormInstance>(null);
  const [file, setFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const file_Size = 125829120; /* Just only NUMBER */
  const onReset = () => {
    formRef.current?.resetFields();
    setFile(null);
  };
  const createFormData = (values: ITemplateValues) => {
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('description', values.description);
    formData.append('type', values.type);
    if (file) {
      formData.append('file', file);
    }
    return formData;
  };

  const onFinish = async (values: ITemplateValues) => {
    setIsLoading(true);
    try {
      const formData = createFormData(values);
      await api.post('/template', formData);
      toast.success('Template atualizado com sucesso');

      onReset();
      setFile(null);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <LayoutAppCMS>
      <PageDescription
        description="Cadastre um novo template"
        title={title}
        breadcrumb={breadcrumb}
        buttons={buttons}
      />
      <Container>
        <Content>
          <Form
            ref={formRef}
            labelCol={{ span: 8 }}
            autoComplete="off"
            wrapperCol={{ span: 10 }}
            onFinish={onFinish}
            style={{ width: '100%' }}
            onFinishFailed={error => {
              console.log({ error });
            }}
          >
            <Form.Item
              name="name"
              label="Nome"
              rules={[
                {
                  required: true,
                  message: 'Por favor informe seu nome',
                },
                { whitespace: true },
                { min: 3 },
              ]}
              hasFeedback
            >
              <Input maxLength={30} placeholder="Informe o nome" required />
            </Form.Item>
            <Form.Item
              name="description"
              label="Descrição"
              rules={[
                {
                  required: true,
                  message: 'Por favor informe a descrição',
                },
                { whitespace: true },
              ]}
              hasFeedback
            >
              <Input placeholder="Informe o nome" required />
            </Form.Item>
            <Form.Item
              name="type"
              label="Tipo de template"
              rules={[
                {
                  required: true,
                  message: 'Por favor informe o tipo',
                },
                { whitespace: true },
                { min: 3 },
              ]}
              hasFeedback
              required
            >
              <Select
                defaultValue="Selecione um tipo"
                options={[
                  { value: 'Monitoramento', label: 'Monitoramento' },
                  { value: 'Pontos', label: 'Pontos' },
                  { value: 'Poços e Sondagens', label: 'Poços e Sondagens' },
                  {
                    value: 'Acreditação de Laboratorio',
                    label: 'Acreditação de Laboratorio',
                  },
                  { value: 'Parâmetros IEMA', label: 'Parâmetros IEMA' },
                ]}
              />
            </Form.Item>
            <Form.Item
              label="Upload"
              name="file"
              valuePropName="fileList"
              rules={[
                {
                  required: true,
                  message: 'Por favor adicione um Template',
                },
              ]}
              getValueFromEvent={event => {
                return event?.fileList;
              }}
            >
              <Upload
                showUploadList={false}
                maxCount={1}
                beforeUpload={file => {
                  return new Promise((resolve, reject) => {
                    if (file && file.size > file_Size) {
                      reject(new Error());
                      toast.error('Tamanho de arquivo excedido');
                    } else {
                      setFile(file);
                      resolve('Sucess');
                    }
                  });
                }}
              >
                <Button icon={<UploadOutlined />}>Fazer Upload</Button>
                {file && (
                  <span style={{ marginLeft: '10px' }}>{file?.name}</span>
                )}
              </Upload>
            </Form.Item>
            <ContentButton>
              <Button type="primary" loading={isLoading} htmlType="submit">
                Cadastrar
              </Button>
              <Button
                style={{ marginLeft: '10px' }}
                htmlType="button"
                danger
                onClick={onReset}
              >
                Limpar
              </Button>
            </ContentButton>
          </Form>
        </Content>
      </Container>
    </LayoutAppCMS>
  );
}
