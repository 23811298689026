import styled from 'styled-components';

export const ContentButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 64px;
  align-items: center; 
`;

export const ContentWarning = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  
  @media (max-width: 960px) {
   flex-direction:column;
   align-items:flex-start;
   .ant-select{
    width:100%;
  }
  button{
    width:100%;
  }
   }
`

export const Warning = styled.span`
  text-align:center;
  margin-left: 80px;
  font-size: medium;
  font-weight:500;
  @media (max-width: 960px) {
   display: none;
   }

`
export const ContentTable = styled.div`
  overflow-x: auto;

`