import React, { useEffect, useState } from 'react';
import {
  ArrowLeftOutlined,
  DiffOutlined,
  DownloadOutlined,
  HomeFilled,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Space, Spin, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { TitleProps } from '../../../../global/interfaces/TitleProps';
import useDocumentTitle from '../../../../hooks/useDocumentTitle';
import LayoutAppCMS from '../../../../layouts/app';
import { PageDescription } from '../../../../components/shered/HeaderCards';
import { Button } from '../../../../components/shered/Button';
import { ContentTable } from './styles';
import api from '../../../../services/api';
import { convertDate } from '../../../../utils/dateConvert';
import '../../../../global/tableStyle.css';

const breadcrumb = [
  {
    label: <HomeFilled />,
    link: '/dashboard',
  },
  {
    label: 'Templates',
  },
];
interface IPropsTemplate {
  key: React.Key;
  _id: string;
  description: string;
  url: string;
}

const columns: ColumnsType<IPropsTemplate> = [
  {
    title: 'Nome',
    dataIndex: '_id',
    key: '_id',
    width: 300,
  },
  {
    title: 'Data',
    dataIndex: 'lastCreatedAt',
    key: 'lastCreatedAt',
    render: text => convertDate(text),
    width: 150,
    align: 'center',
  },
  {
    title: 'Ações',
    key: 'action',
    render: (_, record) => (
      <Space size="middle">
        <a
          href={`${process.env.REACT_APP_URL_API}/template/download/templates/${record.url}`}
          rel="noopener noreferrer"
          aria-label="Download"
        >
          <DownloadOutlined style={{ fontSize: 24 }} />
        </a>
      </Space>
    ),
    align: 'center',
    width: 150,
  },
];

const buttons = [
  <Link to="/dashboard">
    <Button icon={<ArrowLeftOutlined />} size="large">
      Voltar
    </Button>
  </Link>,
  <Link to="/templates/novo-Template">
    <Button size="large" icon={<DiffOutlined />}>
      Novo template
    </Button>
  </Link>,
];

export function TemplateList({ title }: TitleProps) {
  useDocumentTitle(title);
  const [enterprise, setEnterprise] = useState<IPropsTemplate[]>([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const AsyncApi = async () => {
      try {
        const TemplateData = await api.get('/Template');
        const data: IPropsTemplate[] = [];
        TemplateData.data.map((item: IPropsTemplate) => {
          const dataItem = {
            ...item,
            key: item._id,
          };
          data.push(dataItem);
        });
        setEnterprise(data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    AsyncApi();
  }, []);

  return (
    <LayoutAppCMS>
      <PageDescription
        description="Visualize ou cadastre novos templates em Excel para envio de dados."
        title={title}
        breadcrumb={breadcrumb}
        buttons={buttons}
      />
      <ContentTable>
        {loading ? (
          <Spin tip="Carregando..." size="large">
            <div className="content" />
          </Spin>
        ) : (
          <Table
            rowClassName="tableBackground"
            pagination={false}
            columns={columns}
            dataSource={enterprise}
            expandable={{
              expandedRowRender: record => <p>{record.description}</p>,
              rowExpandable: record => record.description !== '',
            }}
          />
        )}
      </ContentTable>
    </LayoutAppCMS>
  );
}
