import styled from 'styled-components';

export const ContentTable = styled.div`
  margin-top: 1rem;
  width: 100%;
`;

export const SpaceButton = styled.div`
  button {
    margin-right: 1rem;
  }
`;

export const FilterContainer = styled.div`
  padding: 8px;
`;
