import React from 'react';
import { Route } from 'react-router-dom';
import { EnterpriseCreate } from '../views/app/enterprise/Create';
import { Enterprise } from '../views/app/enterprise/List';

const routes = [
  {
    path: '/enterprise',
    component: <Enterprise title="Empreendimentos" />,
    key: 'enterprise',
  },
  {
    path: '/enterprise/create',
    component: <EnterpriseCreate title="Buscar empreendimento" />,
    key: 'enterprise_create',
  },
];

const EnterpriseRouter: React.FC = () => (
  <>
    {routes.map((route, key) => (
      <Route path={route.path} key={key} exact>
        {route.component}
      </Route>
    ))}
  </>
);

export default EnterpriseRouter;
