const treatmentError = (err: any) => {
  if (err.message) {
    if (err.message.message) {
      if (err.message.message.message) {
        return err.message.message.message;
      }
      return err.message.message;
    }
    return err.message;
  }
  return err;
};

export { treatmentError };
