import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 1rem;
  width: 100%;
`;
export const ContentButton = styled.div`
  margin: 2.5rem 0 2.5rem;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  height: 4rem;
  align-items: center;
  button {
    margin-left: 1rem;
  }
`;
