import React, { useEffect } from 'react';
import {
  ArrowLeftOutlined,
  DownloadOutlined,
  HomeFilled,
} from '@ant-design/icons';

import axios from 'axios';
import dayjs from 'dayjs';
import useDocumentTitle from '../../../../../hooks/useDocumentTitle';
import LayoutAppCMS from '../../../../../layouts/app';
import { PageDescription } from '../../../../../components/shered/HeaderCards';
import { Button } from '../../../../../components/shered/Button';
import { Container, ContentButton } from './styles';
import { initialForm } from '../multiStepForm.tsx';

interface IFinally {
  title?: string;
  onBack?: any;
  conditionResponse: any;
  stationResponse: any;
  setFormData: any;
  setStep: any;
  setShowAcordion: any;
  setAllData: any;
  enterpriseName: string;
  processName: string;
}

export function FinalStep({
  title,
  onBack,
  conditionResponse,
  stationResponse,
  setFormData,
  setStep,
  setShowAcordion,
  setAllData,
  enterpriseName,
  processName,
}: IFinally) {
  const breadcrumb = [
    {
      label: <HomeFilled />,
      link: '/dashboard',
    },
    {
      label: 'Empreendimentos',
      link: '/enterprise',
    },
    {
      label: `${enterpriseName}`,
    },
    {
      label: `${processName}`,
    },
    {
      label: 'Nova condicionante',
    },
  ];

  useDocumentTitle(title);
  const token = localStorage.getItem('token');
  function downloadFile(data: any) {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API}/file/download/${data?.folder}/${data?.file_name}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));

      const fileNameParts = data.file_name.split('_');
      const baseFileName = fileNameParts.slice(0, -1).join('_');

      const currentDate = dayjs().format('DD/MM/YYYY HH:mm:ss');
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${processName}_${baseFileName}_${currentDate}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    });
  }

  const handleFinish = () => {
    setFormData(initialForm);
    setStep(0);
    setShowAcordion(1);
    setAllData([initialForm]);
  };

  const buttons = [
    <Button onClick={() => onBack(2)} icon={<ArrowLeftOutlined />} size="large">
      Voltar
    </Button>,
    <Button type="primary" onClick={handleFinish} size="large">
      Finalizar
    </Button>,
  ];

  useEffect(() => {
    if (conditionResponse !== null) {
      downloadFile(conditionResponse);
      downloadFile(stationResponse);
    }
  }, [conditionResponse]);

  return (
    <LayoutAppCMS>
      <PageDescription
        title="Nova condicionante "
        description="Download do arquivo"
        breadcrumb={breadcrumb}
        buttons={buttons}
      />
      <Container>
        <ContentButton>
          <Button
            shape="round"
            onClick={() => downloadFile(stationResponse)}
            icon={<DownloadOutlined style={{ fontSize: 24 }} />}
          >
            Arquivo de pontos de monitoramento
          </Button>
          <Button
            shape="round"
            onClick={() => downloadFile(conditionResponse)}
            icon={<DownloadOutlined style={{ fontSize: 24 }} />}
          >
            Arquivo de condicionantes criadas
          </Button>
        </ContentButton>
      </Container>
    </LayoutAppCMS>
  );
}
