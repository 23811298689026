import React from 'react';
import Header from '../../components/layout/Header';
import { AppProps } from './interface';
import { Content } from './styled';

const LayoutAppCMS = ({ children }: AppProps) => {
  return (
    <>
      <Header />
      <Content>{children}</Content>
    </>
  );
};

export default LayoutAppCMS;
